import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getAllOrganizations, deleteOrganization } from './OpenGradaranApi.js';
import { PageFooter, BackArrow, Pair } from './utils/CustomViewUtil.js';
import {
  OrganizationType,
  EmptyOrganization,
  getLabelByOptionValue,
  OrganizationTypeOptions,
  ResourceName,
  canEditContent,
} from './Constant.js';
import { AppContext } from './AppContext.js';
import { withTranslation } from 'react-i18next';
import { getText, getSortField } from './utils/TranslationUtil.js';

/* This is a higher order component that
 *  inject a special prop   to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouter;
}

class OrganizationListPaginated extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      organizations: [],
      selectedType: OrganizationType.LIBRARY,
      currentPage: 0,
      totalPageCount: 0,
      totalElementCount: 0,
      pageOffset: 0,
      pageSize: 0,
      sortField: 'id',
    };
    this.remove = this.remove.bind(this);
    this.paginate = this.paginate.bind(this);
    this.handleTypeOptionChange = this.handleTypeOptionChange.bind(this);
    this.handleSort = this.handleSort.bind(this);
  }

  async componentDidMount() {
    const { appState } = this.context;
    const selectedPage =
      appState.selectedResource === ResourceName.ORGANIZATION
        ? appState.selectedPage
        : 0;

    this.setState({ isLoading: true });
    this.paginate({ selected: selectedPage });
  }

  async remove(id) {
    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this entry?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    this.setState({ isLoading: true });
    await deleteOrganization(id).then((organizationResponse) => {
      if (organizationResponse.message == null) {
        let updatedOrganizations = [...this.state.organizations].filter(
          (i) => i.id !== id
        );
        this.setState({
          organizations: updatedOrganizations,
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: false });
        //report error
      }
    });
  }

  async handleSort(field) {
    this.setState(
      { sortField: field, isLoading: true },
      () => this.paginate({ selected: 0 }) // Reset to the first page when sorting
    );
  }

  async paginate({ selected }) {
    const { updateAppSelectedPage } = this.context;
    const { sortField } = this.state;
    updateAppSelectedPage(ResourceName.ORGANIZATION, selected);

    this.setState({ currentPage: selected });

    await getAllOrganizations(selected, sortField)
      .then((response) => response.json())
      .then((responseBody) => {
        var respData = responseBody.data;
        this.setState((state) => {
          return {
            isLoading: false,
            organizations: respData.page,
            currentPage: selected,
            totalPageCount: respData.totalPages,
            totalElementCount: respData.totalElements,
            pageOffset: respData.offset,
            pageSize: respData.pageSize,
          };
        });
        // this.setState({ isLoading: false });
      });
  }

  handleTypeOptionChange(event) {
    this.setState({ selectedType: event.target.value });
  }

  render() {
    const { t } = this.props;
    const { appState } = this.context;
    const language = appState.language;

    const {
      isLoading,
      organizations,
      selectedType,
      currentPage,
      totalElementCount,
      totalPageCount,
      pageSize,
      pageOffset,
    } = this.state;

    if (isLoading) {
      return <p>Loading...</p>;
    }

    var organizationNo = 0;
    const organizationList = organizations.map((organization) => {
      const orgName = getText(organization.name, organization.nameAm, language);
      const orgCity = getText(
        organization.address?.city,
        organization.address?.cityAm,
        language
      );

      const orgProvince = getText(
        organization.address?.province,
        organization.address?.provinceAm,
        language
      ).toUpperCase();

      return (
        <tr key={organization.id}>
          {canEditContent() && <td>{organization.id}</td>}
          {/* <td>{++organizationNo + pageOffset * pageSize}</td> */}
          <td style={{ whiteSpace: 'nowrap' }}>
            <Link
              to={`/organizations/page/${organization.id}`}
              state={{ organization }}
            >
              {orgName}
            </Link>
          </td>
          <td>
            {t(
              getLabelByOptionValue(OrganizationTypeOptions, organization.type)
            )}
          </td>
          {/* <td>{organization.parentOrganization?.name}</td> */}
          {/* <td>{organization.alpParticipant ? '✓' : ''}</td> */}
          <td>{orgCity}</td>
          <td>{orgProvince}</td>
          {/* <td>{organization.status}</td> */}
          {canEditContent() && (
            <td>
              <ButtonGroup>
                <Button
                  size='sm'
                  color='primary'
                  tag={Link}
                  to={'/organizations/' + organization.id}
                  state={{ organization }}
                >
                  {t('Edit')}
                </Button>
                <Button
                  size='sm'
                  color='danger'
                  onClick={() => this.remove(organization.id)}
                >
                  {t('Delete')}
                </Button>
              </ButtonGroup>
            </td>
          )}
        </tr>
      );
    });

    return (
      <div>
        <Container fluid className='paginated-list-container'>
          <BackArrow />
          {canEditContent() && (
            <div className='float-right'>
              <Button
                color='success'
                tag={Link}
                to='/organizations/new'
                state={{ organization: { ...EmptyOrganization } }}
              >
                {t('Add Organization')}
              </Button>
            </div>
          )}
          <br />
          <h3>{t('Organizations')}</h3>

          <Pair label={'Total'} value={totalElementCount} />

          <Table className='mt-4'>
            <thead>
              <tr>
                {canEditContent() && <th width='5%'>ID</th>}
                <th
                  width='15%'
                  onClick={
                    () => this.handleSort('name')
                    // this.handleSort(getSortField('name', 'nameAm', language))
                  }
                  style={{ cursor: 'pointer' }}
                >
                  {t('Name')}
                </th>
                <th
                  width='10%'
                  onClick={() => this.handleSort('type')}
                  style={{ cursor: 'pointer' }}
                >
                  {t('Type')}
                </th>
                {/* <th width='15%'>{t('Parent Organization')}</th> */}
                {/* <th width='10%'>{t('ALP Participant')}</th> */}
                <th
                  width='15%'
                  onClick={() => this.handleSort('address.city')}
                  style={{ cursor: 'pointer' }}
                >
                  {t('City')}/{t('Village')}
                </th>
                <th
                  width='15%'
                  onClick={() => this.handleSort('address.province')}
                  style={{ cursor: 'pointer' }}
                >
                  {t('Province')}
                </th>
                {/* <th width='10%'>{t('Status')}</th> */}
                {canEditContent() && <th width='20%'>{t('Actions')}</th>}
              </tr>
            </thead>
            <tbody>{organizationList}</tbody>
          </Table>
          <PageFooter
            totalPageCount={totalPageCount}
            currentPage={currentPage}
            paginate={this.paginate}
          />
        </Container>
      </div>
    );
  }
}
const HOCOrganizationListPaginated = withRouter(
  withTranslation()(OrganizationListPaginated)
);
export default HOCOrganizationListPaginated;
