import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  getAllWorks,
  getWorksByAuthorId,
  getWorksByEditionId,
  getWorksBySeriesId,
  searchWorksByKeyword,
  deleteWork,
} from './OpenGradaranApi.js';
import {
  PageFooter,
  BackToCatalog,
  BookCover,
  ComboTitle,
  hasCoverImage,
  ViewSubtitle,
  Pair,
} from './utils/CustomViewUtil.js';
import {
  EmptyAuthor,
  EmptyEdition,
  EmptySeries,
  EmptyWork,
  ResourceName,
  canEditContent,
  LanguageOptions,
  getLabelByOptionValue,
} from './Constant.js';
import { AppContext } from './AppContext.js';
import { linkStyleNoUnderline } from './InlineStyles.js';
import { withTranslation } from 'react-i18next';
import { getEllipsizedText } from './utils/TextUtil.js';
import { createTranslator, CaseFormat } from './utils/TranslationUtil.js';

/* This is a higher order component that
 *  inject a special prop   to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class WorkListPaginated extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      author: { ...EmptyAuthor },
      edition: { ...EmptyEdition },
      series: { ...EmptySeries },
      works: [],
      expandedDescriptions: {}, // Tracks which book descriptions are expanded
      keyword: '',
      currentPage: 0,
      totalPageCount: 0,
      totalElementCount: 0,
      pageOffset: 0,
      pageSize: 0,
    };
    this.remove = this.remove.bind(this);
    this.paginate = this.paginate.bind(this);
  }

  toggleDescription = (id) => {
    this.setState((prevState) => ({
      expandedDescriptions: {
        ...prevState.expandedDescriptions,
        [id]: !prevState.expandedDescriptions[id],
      },
    }));
  };

  getDescription = (description, isExpanded) => {
    return isExpanded
      ? description
      : description.length > 200
      ? `${description.slice(0, 200)}...`
      : description;
  };

  async componentDidMount() {
    const queryParams = new URLSearchParams(this.props.location.search);
    const keyword = queryParams.get('keyword');
    const { appState } = this.context;
    const selectedPage =
      appState.selectedResource === ResourceName.WORK
        ? appState.selectedPage
        : 0;

    if (this.props.location.state?.author) {
      this.setState(
        {
          author: this.props.location.state.author,
        },
        () => {
          // This code will be executed after the state has been updated
          this.setState({ isLoading: true });
          this.paginate({ selected: selectedPage });
        }
      );
    } else if (this.props.location.state?.edition) {
      this.setState(
        {
          edition: this.props.location.state.edition,
        },
        () => {
          // This code will be executed after the state has been updated
          this.setState({ isLoading: true });
          this.paginate({ selected: selectedPage });
        }
      );
    } else if (this.props.location.state?.series) {
      this.setState(
        {
          series: this.props.location.state.series,
        },
        () => {
          // This code will be executed after the state has been updated
          this.setState({ isLoading: true });
          this.paginate({ selected: selectedPage });
        }
      );
    } else if (keyword) {
      this.setState(
        {
          keyword,
        },
        () => {
          // This code will be executed after the state has been updated
          this.setState({ isLoading: true });
          this.paginate({ selected: selectedPage });
        }
      );
    } else {
      this.setState({ isLoading: true });
      this.paginate({ selected: selectedPage });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      const queryParams = new URLSearchParams(this.props.location.search);
      const keyword = queryParams.get('keyword');
      this.setState(
        {
          keyword,
        },
        () => {
          // This code will be executed after the state has been updated
          this.setState({ isLoading: true });
          this.paginate({ selected: 0 });
        }
      );
    }
  }

  async remove(id) {
    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this entry?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    await deleteWork(id).then((workResponse) => {
      if (workResponse.message == null) {
        let updatedWorks = [...this.state.works].filter((i) => i.id !== id);
        this.setState({ works: updatedWorks, isLoading: false });
      } else {
        this.setState({ isLoading: false });
        //report error
      }
    });
  }

  async paginate({ selected }) {
    const { updateAppSelectedPage } = this.context;
    updateAppSelectedPage(ResourceName.WORK, selected);

    const { author, edition, series, keyword } = this.state;

    this.setState({ currentPage: selected, expandedDescriptions: {} });

    var workPageResponse;
    if (author.id) {
      workPageResponse = await (
        await getWorksByAuthorId(author.id, selected)
      ).json();
    } else if (edition.id) {
      workPageResponse = await (
        await getWorksByEditionId(edition.id, selected)
      ).json();
    } else if (series.id) {
      workPageResponse = await (
        await getWorksBySeriesId(series.id, selected)
      ).json();
    } else if (keyword) {
      workPageResponse = await (
        await searchWorksByKeyword(keyword, selected)
      ).json();
    } else {
      workPageResponse = await (await getAllWorks(selected)).json();
    }
    if (workPageResponse.message == null) {
      const respData = workPageResponse.data;
      this.setState((state) => {
        return {
          isLoading: false,
          works: respData.page,
          currentPage: selected,
          totalPageCount: respData.totalPages,
          totalElementCount: respData.totalElements,
          pageOffset: respData.offset,
          pageSize: respData.pageSize,
        };
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  authorList(authors) {
    return authors.map((author, i) => (
      <Button key={i} color='link'>
        <Link
          to={`/authors/page/${author.id}`}
          state={{ author }}
          style={linkStyleNoUnderline}
        >
          {author.name}
        </Link>
      </Button>
    ));
  }
  getPageTitle(author, edition, series) {
    const { t } = this.props;
    if (author.name) {
      return (
        <div>
          <h2>{t('Works')}</h2>
          <ViewSubtitle
            name={author.name}
            resourceName={'Author'}
            nameClass={'dark-green'}
          />
        </div>
      );
    } else if (series.title) {
      return (
        <div>
          <h2>{t('Works')}</h2>
          <ViewSubtitle
            name={series.title}
            resourceName={'Series'}
            nameClass={'dark-green'}
          />
        </div>
      );
    } else if (hasCoverImage(edition)) {
      return <BookCover edition={edition} />;
    } else if (edition.title) {
      return `${t('Works Included in Edition')}: "${edition.title}"`;
    } else {
      return <h2>{t('Works')}</h2>;
    }
  }

  render() {
    const { t } = this.props;
    const translate = createTranslator(t);
    const canEdit = canEditContent();
    const { appState } = this.context;
    const {
      isLoading,
      author,
      edition,
      series,
      works,
      expandedDescriptions,
      currentPage,
      totalElementCount,
      totalPageCount,
      pageSize,
      pageOffset,
    } = this.state;

    if (isLoading) {
      return <p>Loading...</p>;
    }
    const pageTitle = this.getPageTitle(author, edition, series);
    const workList = works.map((work) => {
      const isExpanded = expandedDescriptions[work.id] || false;
      const originalLanguage = getLabelByOptionValue(
        LanguageOptions,
        work.originalLanguage
      );

      return (
        <tr key={work.id}>
          {canEditContent() && <td>{work.id}</td>}
          {/* <td style={{ whiteSpace: 'nowrap' }}> */}
          <td style={{ minWidth: '200px' }}>
            <Link
              to={`/works/page/${work.id}`}
              state={{ work }}
              style={linkStyleNoUnderline}
            >
              <ComboTitle
                title={work.title}
                subtitle={work.subtitle}
                seriesTitle={work.series?.title}
                seriesItemNumber={work.seriesItemNumber}
              />
            </Link>
          </td>
          <td style={{ minWidth: '150px' }}>{this.authorList(work.authors)}</td>
          <td style={{ minWidth: '350px' }}>
            <p>
              {this.getDescription(work.description, isExpanded)}
              {work.description.length > 200 && (
                <span
                  onClick={() => this.toggleDescription(work.id)}
                  style={{
                    color: 'blue',
                    cursor: 'pointer',
                    marginLeft: '5px',
                  }}
                >
                  {isExpanded ? 'read less' : 'read more'}
                </span>
              )}
            </p>
          </td>
          {/* <td>{getEllipsizedText(work.description, 300)}</td> */}
          <td>{translate(originalLanguage, CaseFormat.CAPITALIZED)}</td>
          {/* <td>{work.status}</td> */}
          {canEditContent() && (
            <td>
              <ButtonGroup>
                <Button
                  size='sm'
                  color='primary'
                  tag={Link}
                  to={`/works/${work.id}`}
                  state={{ work }}
                >
                  {t('Edit')}
                </Button>
                <Button
                  size='sm'
                  color='danger'
                  onClick={() => this.remove(work.id)}
                >
                  {t('Delete')}
                </Button>
              </ButtonGroup>
            </td>
          )}
        </tr>
      );
    });

    return (
      <div>
        <Container fluid className='paginated-list-container'>
          <BackToCatalog />
          {canEditContent() && (
            <div className='float-right'>
              <Button
                color='success'
                tag={Link}
                to='/works/new'
                state={{ work: { ...EmptyWork }, edition, author }}
              >
                {t('Add Work')}
              </Button>
            </div>
          )}
          <br />
          <h3>{pageTitle}</h3>

          <Pair label={'Total'} value={totalElementCount} />

          <Table className='mt-4'>
            <thead>
              <tr>
                {canEditContent() && <th width='5%'>{t('ID')}</th>}
                <th width='25%'>{t('Title')}</th>
                <th width='15%'>{t('Author')}</th>
                <th width='20%'>{t('Description')}</th>
                <th width='10%'>{t('Original Language')}</th>
                {/* <th width='10%'>{t('Status')}</th> */}
                {canEditContent() && <th width='15%'>{t('Actions')}</th>}
              </tr>
            </thead>
            <tbody>{workList}</tbody>
          </Table>
          <PageFooter
            totalPageCount={totalPageCount}
            currentPage={currentPage}
            paginate={this.paginate}
          />
        </Container>
      </div>
    );
  }
}
const HOCWorkListPaginated = withRouter(withTranslation()(WorkListPaginated));
export default HOCWorkListPaginated;
