import React, { Component } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilSquare } from '@fortawesome/free-solid-svg-icons';
import {
  ViewTitle,
  BackButton,
  DateRangeFormatted,
  PairLink,
} from './utils/CustomViewUtil.js';
import {
  EmptyOrganization,
  EmptyDonationEvent,
  canEditContent,
} from './Constant.js';
import { backgroundStyle } from './InlineStyles.js';
import { withTranslation } from 'react-i18next';
import { AppContext } from './AppContext.js';
import {
  getText,
  createTranslator,
  CaseFormat,
} from './utils/TranslationUtil.js';
import {
  isValidApiResponse,
  getArmenianLibraryProject,
  getDonationEvent,
} from './OpenGradaranApi.js';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class DonationEventView extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    const { organization, donationEvent } = this.props;
    this.state = {
      organization: organization || EmptyOrganization,
      donationEvent: donationEvent || EmptyDonationEvent,
    };

    this.handleEditClick = this.handleEditClick.bind(this);
  }

  async componentDidMount() {
    var donationEvent = this.props.location.state?.donationEvent;
    var organization = this.props.location.state?.organization;
    const id = this.props.params.id;

    if (!donationEvent && id) {
      const donationEventResp = await (await getDonationEvent(id)).json();
      if (isValidApiResponse(donationEventResp)) {
        donationEvent = donationEventResp;
      } else {
        // handle error
      }
    }
    organization = organization || donationEvent?.organization;
    if (!organization) {
      const alpResponse = await (await getArmenianLibraryProject()).json();
      if (isValidApiResponse(alpResponse)) {
        const page = alpResponse.data.page;
        if (page?.length) {
          organization = page[0];
        }
      }
    }
    if (donationEvent || organization) {
      this.setState({
        organization: organization || EmptyOrganization,
        donationEvent: donationEvent || EmptyDonationEvent,
      });
    }
  }

  handleGoBack = () => {
    this.props.navigate(-1); // Use history.goBack() to navigate back
  };

  handleEditClick(event) {
    event.preventDefault();
    console.log('Edit clicked!');
    const { organization, donationEvent } = this.state;
    this.props.navigate(`/donation-events/${donationEvent.id}`, {
      state: { organization, donationEvent },
    });
  }

  render() {
    const { t } = this.props;
    const { appState } = this.context;
    const language = appState.language;

    const { organization, donationEvent } = this.state;
    if (!donationEvent) {
      return null;
    }
    const donationsLink = (
      <Link
        to={`/donation-events/${donationEvent.id}/donations`}
        state={{ donationEvent }}
        className='custom-green'
      >
        {t('Donations').toUpperCase()}
      </Link>
    );

    const shippingsLink = (
      <Link
        to={`/donation-events/${donationEvent.id}/shipping-boxes`}
        state={{ donationEvent }}
        className='custom-green'
      >
        {t('Shippings')}
      </Link>
    );

    return (
      <div>
        <div style={backgroundStyle}>
          <Container
            className='subview-container-blue'
            style={{ width: '50%' }}
          >
            <div
              style={{
                position: 'relative',
                padding: '30px',
              }}
            >
              {/* Edit icon at the upper right corner */}
              {canEditContent() && (
                <div className='edit-pencil-container'>
                  <a href='' onClick={this.handleEditClick}>
                    <FontAwesomeIcon
                      icon={faPencilSquare}
                      className='edit-pencil-small'
                    />
                  </a>
                </div>
              )}
              <ViewTitle
                name={getText(
                  donationEvent.name,
                  donationEvent.nameAm,
                  language
                )}
                resourceName={'Donation Event'}
              />
              <PairLink
                label={'Book provider'}
                link={
                  <Link
                    // to={`/organizations/page/${organization.id}`}
                    to={'/about-us'}
                    // state={{ organization }}
                    className='custom-green'
                  >
                    {getText(organization.name, organization.nameAm, language)}
                  </Link>
                }
              />
              <p>
                {getText(
                  donationEvent.description,
                  donationEvent.descriptionAm,
                  language
                )}
              </p>
              {
                <DateRangeFormatted
                  startDate={donationEvent.startDate}
                  endDate={donationEvent.endDate}
                  language={language}
                />
              }
              <p>{donationsLink}</p>
              <p>{canEditContent() && shippingsLink}</p>
              <p>
                <BackButton onClick={this.handleGoBack} />
              </p>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
const HOCDonationEventView = withRouter(withTranslation()(DonationEventView));
export default HOCDonationEventView;
