import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import {
  Link,
  useParams,
  useNavigate,
  useLocation,
  useMatch,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  getDonationsByOrganizationId,
  getDonationsByDonationEventId,
  getDonationsByShippingBoxId,
  getDonationsByDonorId,
  getDonations,
  getOrganization,
  getPersonProfile,
  getDonationEvent,
  getShippingBox,
  deleteDonation,
  fetchEntity,
  fetchAndValidate,
  isValidApiResponse,
} from './OpenGradaranApi.js';
import {
  PageFooter,
  BackArrow,
  BookCover,
  ViewSubtitle,
  Pair,
} from './utils/CustomViewUtil.js';
import {
  EmptyOrganization,
  EmptyDonationEvent,
  EmptyShippingBox,
  EmptyPersonProfile,
  ResourceName,
  canEditContent,
  getLabelByOptionValue,
  DonationStatusOptions,
  ShippingStatusOptions,
} from './Constant.js';
import { AppContext } from './AppContext.js';
import { withTranslation } from 'react-i18next';
import { getText } from './utils/TranslationUtil.js';
import { getFullName } from './utils/TextUtil.js';
import { createTranslator, CaseFormat } from './utils/TranslationUtil.js';

/* This is a higher order component that
 *  inject a special prop   to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    const isOrgRoute = useMatch('/organizations/:id/donations');
    const isEventRoute = useMatch('/donation-events/:id/donations');
    const isBoxRoute = useMatch('/shipping-boxes/:id/donations');
    const isDonorRoute = useMatch('/donors/:id/donations');

    let routeType = '';
    if (isOrgRoute) routeType = 'organizations';
    if (isEventRoute) routeType = 'donation-events';
    if (isBoxRoute) routeType = 'shipping-boxes';
    if (isDonorRoute) routeType = 'donors';
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
        routeType={routeType}
      />
    );
  }
  return ComponentWithRouter;
}

class DonationListPaginated extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      organizationFilter: { ...EmptyOrganization },
      donationEventFilter: { ...EmptyDonationEvent },
      shippingBoxFilter: { ...EmptyShippingBox },
      donorFilter: { ...EmptyPersonProfile },
      donations: [],
      currentPage: 0,
      totalPageCount: 0,
      totalElementCount: 0,
      pageOffset: 0,
      pageSize: 0,
      sortField: 'id',
    };
    this.remove = this.remove.bind(this);
    this.paginate = this.paginate.bind(this);
    this.handleSort = this.handleSort.bind(this);
  }

  async componentDidMount() {
    const { appState } = this.context;
    const { params, routeType, location } = this.props;
    const { id } = params;

    let { donationEvent, shippingBox, donor, organization, pageNo } =
      location.state || {};

    const selectedPage =
      pageNo ??
      (appState.selectedResource === ResourceName.DONATION
        ? appState.selectedPage
        : 0);

    if (shippingBox?.id) {
      donationEvent = shippingBox.donationEvent;
      organization = shippingBox.organization;
    } else if (donationEvent?.id) {
      organization = donationEvent.organization;
    } else if (organization?.id) {
      organization = await this.fetchOrganization(organization, id, routeType);
    }

    donationEvent = await fetchEntity(
      donationEvent,
      id,
      routeType,
      'donation-events',
      getDonationEvent,
      EmptyDonationEvent
    );

    shippingBox = await fetchEntity(
      shippingBox,
      id,
      routeType,
      'shipping-boxes',
      getShippingBox,
      EmptyShippingBox
    );

    donor = await fetchEntity(
      donor,
      id,
      routeType,
      'donors',
      getPersonProfile,
      EmptyPersonProfile
    );

    this.setState(
      {
        organizationFilter: organization,
        donationEventFilter: donationEvent,
        shippingBoxFilter: shippingBox,
        donorFilter: donor,
      },
      () => {
        this.setState({ isLoading: true });
        this.paginate({ selected: selectedPage });
      }
    );
  }

  /**
   * Fetches an organization if it is missing or invalid.
   */
  async fetchOrganization(currentOrganization, id, routeType) {
    if (currentOrganization) return currentOrganization;

    if (id && routeType === 'organizations') {
      return await fetchAndValidate(getOrganization, id, EmptyOrganization);
    }

    const response = await (await getOrganization(1)).json();
    return isValidApiResponse(response) ? response : EmptyOrganization;
  }

  async remove(id) {
    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this entry?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    this.setState({ isLoading: true });
    await deleteDonation(id).then((donationResponse) => {
      if (donationResponse.message == null) {
        let updatedDonations = [...this.state.donations].filter(
          (i) => i.id !== id
        );
        this.setState({
          donations: updatedDonations,
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: false });
        //report error
      }
    });
  }

  async handleSort(field) {
    this.setState(
      { sortField: field, isLoading: true },
      () => this.paginate({ selected: 0 }) // Reset to the first page when sorting
    );
  }

  async paginate({ selected }) {
    const { updateAppSelectedPage } = this.context;
    updateAppSelectedPage(ResourceName.DONATION, selected);

    this.setState({ currentPage: selected });

    const {
      organizationFilter,
      donationEventFilter,
      shippingBoxFilter,
      donorFilter,
      sortField,
    } = this.state;

    const donationPageResponse = await this.fetchDonations(
      {
        organizationFilter,
        donationEventFilter,
        shippingBoxFilter,
        donorFilter,
      },
      selected,
      sortField
    );

    const respData = donationPageResponse?.data;

    if (isValidApiResponse(respData)) {
      this.setState({
        isLoading: false,
        donations: respData.page,
        totalPageCount: respData.totalPages,
        totalElementCount: respData.totalElements,
        pageOffset: respData.offset,
        pageSize: respData.pageSize,
      });
    } else {
      // Handle error (log it, show a message, etc.)
    }
  }

  /**
   * Determines the correct API call based on available filters and fetches donations.
   */
  async fetchDonations(filters, selected, sortField) {
    const {
      shippingBoxFilter,
      donationEventFilter,
      donorFilter,
      organizationFilter,
    } = filters;

    if (shippingBoxFilter?.id) {
      return await this.fetchAndParse(
        getDonationsByShippingBoxId,
        shippingBoxFilter.id,
        selected,
        sortField
      );
    }
    if (donationEventFilter?.id) {
      return await this.fetchAndParse(
        getDonationsByDonationEventId,
        donationEventFilter.id,
        selected,
        sortField
      );
    }
    if (donorFilter?.id) {
      return await this.fetchAndParse(
        getDonationsByDonorId,
        donorFilter.id,
        selected,
        sortField
      );
    }
    if (organizationFilter?.id) {
      return await this.fetchAndParse(
        getDonationsByOrganizationId,
        organizationFilter.id,
        selected,
        sortField
      );
    }
    return await this.fetchAndParse(getDonations, selected, sortField);
  }

  /**
   * Helper function to fetch data and parse JSON response.
   */
  async fetchAndParse(fetchFunction, ...params) {
    const response = await fetchFunction(...params);
    return response.json();
  }

  render() {
    const { t } = this.props;
    const { appState } = this.context;
    const language = appState.language;

    const translate = createTranslator(t);
    const {
      isLoading,
      organizationFilter, //book provider organization
      donationEventFilter,
      shippingBoxFilter,
      donorFilter,
      donations,
      currentPage,
      totalElementCount,
      totalPageCount,
      pageSize,
      pageOffset,
    } = this.state;

    if (isLoading) {
      return <p>Loading...</p>;
    }

    // const bookProviderOrganizationName = getText(
    //   organizationFilter?.name,
    //   organizationFilter?.nameAm,
    //   language
    // );

    const donationList = donations.map((donation) => {
      const donationStatus = getLabelByOptionValue(
        DonationStatusOptions,
        donation.donationStatus
      );
      const shippingStatus = getLabelByOptionValue(
        ShippingStatusOptions,
        donation.shippingStatus
      );
      const book = donation.book;
      const orgName = book.organization
        ? getText(book.organization.name, book.organization.nameAm, language)
        : '';
      return (
        <tr key={donation.id}>
          {/* <td>{donation.id}</td> */}
          <Link
            to={`/donations/page/${donation.id}`}
            state={{ organization: organizationFilter, donation, book }}
            className='button-link-left'
          >
            {donation.id}
            {/* Donation #{donation.id} */}
          </Link>
          <td>
            <Link to={`/books/page/${book.id}`} state={{ book }}>
              <BookCover edition={book.edition} />
            </Link>
          </td>
          <td>
            <Link
              to={`/organizations/page/${book.organization?.id}`}
              state={{ organization: book.organization }}
              className='button-link-left'
            >
              {orgName}
            </Link>
          </td>
          {!donationEventFilter?.id && (
            <td>
              <Link
                to={`/donation-events/page/${donation.donationEvent?.id}`}
                state={{
                  organization: donation.organization,
                  donationEvent: donation.donationEvent,
                }}
                className='button-link-left'
              >
                {getText(
                  donation.donationEvent?.name,
                  donation.donationEvent?.nameAm,
                  language
                )}
              </Link>
            </td>
          )}
          {!donorFilter?.id && (
            <td>
              <Link
                to={`/donors/${donation.donor?.id}/donations`}
                state={{
                  organization: donation.organization,
                  donor: donation.donor,
                  pageNo: 0,
                }}
                className='button-link-left'
              >
                {getFullName(donation.donor)}
              </Link>
            </td>
          )}
          {/* <td>{donation.source}</td> */}
          <td>{translate(donationStatus, CaseFormat.CAPITALIZED)}</td>
          {canEditContent() && (
            <td>{translate(shippingStatus, CaseFormat.CAPITALIZED)}</td>
          )}
          {/* <td>{donation.shippingBox?.id}</td> */}
          {/* <td>{donation.status}</td> */}
          {/* <td>{donation.notes}</td> */}
          {canEditContent() && (
            <td>
              <ButtonGroup>
                <Button
                  size='sm'
                  color='primary'
                  tag={Link}
                  to={`/donations/${donation.id}`}
                  state={{
                    organization: organizationFilter,
                    donation,
                    book: donation.book,
                  }}
                >
                  {t('Edit')}
                </Button>
                <Button
                  size='sm'
                  color='danger'
                  onClick={() => this.remove(donation.id)}
                >
                  {t('Delete')}
                </Button>
              </ButtonGroup>
            </td>
          )}
        </tr>
      );
    });

    return (
      <div>
        <Container fluid className='paginated-list-container'>
          <BackArrow />
          <br />
          <h3>{t('Donations')}</h3>
          {donorFilter?.id && (
            <ViewSubtitle
              name={getFullName(donorFilter)}
              resourceName={'Donor'}
              nameClass={'dark-green'}
            />
          )}

          {donationEventFilter?.id && (
            <h4 className='dark-green'>
              {getText(
                donationEventFilter.name,
                donationEventFilter.nameAm,
                language
              )}
            </h4>
          )}
          {shippingBoxFilter?.id && (
            <h4>
              {t('Shipping Box')}: {shippingBoxFilter.id}
            </h4>
          )}

          <Pair label={'Total'} value={totalElementCount} />

          <Table className='mt-4'>
            <thead>
              <tr>
                <th
                  width='5%'
                  onClick={() => this.handleSort('id')}
                  style={{ cursor: 'pointer' }}
                >
                  ID
                </th>
                <th width='10%'>{t('Book')}</th>
                {/* <th width='13%'>{translate('edition', CaseFormat.CAPITALIZED)}</th> */}
                <th width='12%'>{t('Library')}</th>
                {!donationEventFilter?.id && (
                  <th
                    width='10%'
                    onClick={() => this.handleSort('donationEventId')}
                    style={{ cursor: 'pointer' }}
                  >
                    {t('Donation Event')}
                  </th>
                )}
                {!donorFilter?.id && (
                  <th
                    width='10%'
                    onClick={() => this.handleSort('donorId')}
                    style={{ cursor: 'pointer' }}
                  >
                    {t('Donor')}
                  </th>
                )}
                {/* <th width='10%'>{t('Source')}</th> */}
                <th
                  width='10%'
                  onClick={() => this.handleSort('donationStatus')}
                  style={{ cursor: 'pointer' }}
                >
                  {t('Donation Status')}
                </th>
                {canEditContent() && (
                  <th width='10%'>{t('Shipping Status')}</th>
                )}
                {/* <th width='10%'>{t('Status')}</th> */}
                {/* <th width='10%'>{t('Notes')}</th> */}
                {canEditContent() && <th width='15%'>{t('Actions')}</th>}
              </tr>
            </thead>
            <tbody>{donationList}</tbody>
          </Table>
          <PageFooter
            totalPageCount={totalPageCount}
            currentPage={currentPage}
            paginate={this.paginate}
          />
        </Container>
      </div>
    );
  }
}
const HOCDonationListPaginated = withRouter(
  withTranslation()(DonationListPaginated)
);
export default HOCDonationListPaginated;
