import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from 'reactstrap';
import {
  getOrganization,
  postOrganization,
  deleteOrganization,
  searchOrganizations,
  isValidApiResponse,
} from './OpenGradaranApi.js';
import {
  EmptyOrganization,
  EmptyAddress,
  OrganizationTypeOptions,
  OrganizationType,
} from './Constant.js';
import { backgroundStyle, inputDisableStyle } from './InlineStyles.js';
import {
  FormTitle,
  DeleteButton,
  CloseButton,
  SaveButton,
  StatusFormGroup,
} from './utils/CustomViewUtil.js';
import { withTranslation } from 'react-i18next';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouter;
}

class OrganizationForm extends Component {
  constructor(props) {
    super(props);
    // const initialContacts = InitialContactArray.map((contact) => ({
    //   ...contact,
    // }));
    this.state = {
      organization: { ...EmptyOrganization },
      contacts: [], // initialContacts,
      initAddress: { ...EmptyAddress },
      address: null, //{ ...EmptyAddress },
      parentOrganization: { ...EmptyOrganization },
      parentSearchValue: '', // Value entered in the input box
      parentOrganizationOptions: [],
      isLoading: false, // Loading state for API calls
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleAlpParticipantCheckbox =
      this.handleAlpParticipantCheckbox.bind(this);
    this.handleParentSelectionChange =
      this.handleParentSelectionChange.bind(this);
    this.handleParentInputChange = this.handleParentInputChange.bind(this);

    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.handleDelete = this.handleDelete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    if (this.props.params.id !== 'new') {
      const id = this.props.params.id;
      const organization = await (await getOrganization(id)).json();
      if (organization) {
        const organizationAddress = organization?.address
          ? organization.address
          : { ...EmptyAddress };
        const parentOrganization = organization?.parentOrganization?.id
          ? organization?.parentOrganization
          : { ...EmptyOrganization };
        this.setState({
          organization,
          parentOrganization,
          parentSearchValue: parentOrganization.name,
          contacts: organization.contacts,
          address: organizationAddress,
          initAddress: organizationAddress,
        });
      }
    }
    this.findAndSetParent();
  }

  async handleParentInputChange(event) {
    event.stopPropagation();
    const value = event.target.value;
    this.setState({ parentSearchValue: value, isLoading: true });

    this.findAndSetParent(value);
    if (value.length > 0) {
      this.setState({ isLoading: false, isDropdownVisible: true });
    } else {
      this.setState({ isLoading: false, isDropdownVisible: false });
    }
  }

  handleParentSelectionChange(selectedParent) {
    this.setState({
      parentSearchValue: selectedParent?.name,
      parentOrganization: selectedParent,
      isDropdownVisible: false,
    });
  }

  async findAndSetParent(value = '') {
    const organization = this.state.organization;
    try {
      const optionsResponse = await (await searchOrganizations(value)).json();
      if (isValidApiResponse(optionsResponse.message)) {
        const options = optionsResponse.data.page;
        const filteredOptions = options.filter(
          (item) => item.id !== organization.id
        );
        this.setState({
          parentOrganizationOptions: [EmptyOrganization, ...filteredOptions],
        });
      } else {
        // handle errors
        console.error('Error fetching data:', optionsResponse.message);
      }
    } catch (error) {
      // handle errors
      console.error('Error fetching data:', error);
    }
  }

  handleKeyDown = (event) => {
    if (event.key == 'Enter') {
      event.preventDefault(); // Prevent the default behavior of the "Enter" key
    }
  };

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let organization = { ...this.state.organization };
    organization[name] = value;
    this.setState({ organization });
  }

  handleAlpParticipantCheckbox(event) {
    const { organization } = this.state;
    organization.alpParticipant = event.target.checked;
    this.setState({
      organization,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { organization, parentOrganization } = this.state;

    if (parentOrganization?.id) {
      organization.parentOrganization = parentOrganization;
    }

    // post organization
    var organizationResponse = await (
      await postOrganization(organization)
    ).json();

    if (isValidApiResponse(organizationResponse)) {
      this.props.navigate('/organizations');
    } else {
      //report error
      var error = organizationResponse.message;
    }
  }

  async handleDelete(event) {
    event.preventDefault();

    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this organization?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    const { organization } = this.state;
    if (organization.id) {
      try {
        const organizationResponse = await deleteOrganization(organization.id);
        if (isValidApiResponse(organizationResponse)) {
          this.props.navigate('/organizations');
        } else {
          // Report invalid API response
          console.error('Invalid API response');
        }
      } catch (error) {
        // Handle network or other errors
        console.error('Error deleting organization:', error);
      }
    } else {
      this.props.navigate(-1);
    }
  }

  handleClose(event) {
    event.preventDefault();
    this.props.navigate(-1);
  }

  render() {
    const { t } = this.props;
    const {
      organization,
      contacts,
      address,
      parentOrganization,
      parentOrganizationOptions,
      parentSearchValue,
      isDropdownVisible,
      isLoading,
    } = this.state;

    return (
      <div>
        <div style={backgroundStyle}>
          <Container className='green-white'>
            <Row className='form-container'>
              {
                <FormTitle
                  id={organization.id}
                  name={organization.name}
                  resourceName={'Organization'}
                />
              }
              <Form onSubmit={this.handleSubmit}>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='id'>ID</Label>
                      <Input
                        type='text'
                        name='id'
                        id='id'
                        value={organization.id}
                        readOnly
                        style={inputDisableStyle}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='type'>{t('Organization Type')}</Label>
                      <select
                        value={organization.type?.toUpperCase()}
                        defaultValue={OrganizationType.UNDEFINED}
                        onChange={this.handleChange}
                        className='form-control'
                        name='type'
                        id='type'
                      >
                        {OrganizationTypeOptions.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='parent'>{t('Parent Organization')}</Label>

                      <Input
                        type='text'
                        value={parentSearchValue}
                        onChange={this.handleParentInputChange}
                        onKeyDown={this.handleKeyDown}
                        className='form-control'
                        placeholder={t('Search...')}
                      />
                      {isDropdownVisible && !isLoading && (
                        <div className='dropdown-menu show'>
                          {parentOrganizationOptions.map((option) => (
                            <Button
                              key={option.id}
                              className='dropdown-item'
                              onClick={() =>
                                this.handleParentSelectionChange(option)
                              }
                            >
                              {option.name}
                            </Button>
                          ))}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='name'>{t('Organization Name')}</Label>
                      <Input
                        type='text'
                        name='name'
                        id='name'
                        value={organization.name}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        required
                        placeholder={t('enter organization name')}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='description'>{t('Description')}</Label>
                      <Input
                        type='text'
                        name='description'
                        id='description'
                        value={organization.description}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='nameAm'>
                        {t('Organization Name')} / {t('in Armenian')}
                      </Label>
                      <Input
                        type='text'
                        name='nameAm'
                        id='nameAm'
                        value={organization.nameAm}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label
                        htmlFor='alpParticipant'
                        style={{ marginRight: '20px' }}
                      >
                        {t('ALP Participant')}
                      </Label>
                      <Input
                        type='checkbox'
                        checked={organization.alpParticipant}
                        name='alpParticipant'
                        id='alpParticipant'
                        value={organization.alpParticipant}
                        onChange={this.handleAlpParticipantCheckbox}
                        style={{ transform: 'scale(2)' }}
                        // onKeyDown={this.handleKeyDown}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='descriptionAm'>
                        {t('Description')} / {t('in Armenian')}
                      </Label>
                      <Input
                        type='text'
                        name='descriptionAm'
                        id='descriptionAm'
                        value={organization.descriptionAm}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <StatusFormGroup
                  status={organization.status}
                  handleChange={this.handleChange}
                />
                <FormGroup>
                  <SaveButton />
                  <CloseButton onClick={this.handleClose} />
                  <DeleteButton onClick={this.handleDelete} />
                </FormGroup>
              </Form>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const HOCOrganizationForm = withRouter(withTranslation()(OrganizationForm));
export default HOCOrganizationForm;
