/***  User APIs */
export async function registerUser(user) {
  return fetch('/register', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  });
}

export async function loginUser(user) {
  return fetch('/login', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  });
}

function getHeadersWithToken() {
  const token = localStorage.getItem('token');
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
}

export async function deleteUser(id) {
  return fetch(`/api/v1/users/${id}`, {
    method: 'DELETE',
    headers: getHeadersWithToken(),
  });
}

/**  Author APIs */
export async function getAllAuthors(selectedPage = 0, sortBy = 'name') {
  return fetch(`/api/v1/authors?pageNo=${selectedPage}&sortBy=${sortBy}`);
}

export async function searchAuthorsByKeyword(keyword, selectedPage = 0) {
  return fetch(
    `/api/v1/authors/search?keyword=${keyword}&pageNo=${selectedPage}`
  );
}

export async function getAuthor(id) {
  return fetch(`/api/v1/authors/${id}`);
}

export async function postAuthor(author) {
  return fetch('/api/v1/authors', {
    method: 'POST',
    headers: getHeadersWithToken(),
    body: JSON.stringify(author),
  });
}

export async function addAuthorToWork(workId, author) {
  return fetch(`/api/v1/works/${workId}/authors`, {
    method: 'POST',
    headers: getHeadersWithToken(),
    body: JSON.stringify(author),
  });
}

export async function deleteAuthor(id) {
  return fetch(`/api/v1/authors/${id}`, {
    method: 'DELETE',
    headers: getHeadersWithToken(),
  });
}

/*** Edition APIs */
export async function getEdition(id) {
  return fetch(`/api/v1/editions/${id}`);
}

export async function postEdition(edition) {
  return fetch('/api/v1/editions', {
    method: 'POST',
    headers: getHeadersWithToken(),
    body: JSON.stringify(edition),
  });
}

export async function addEditionToWork(workId, edition) {
  return fetch(`/api/v1/works/${workId}/editions`, {
    method: 'POST',
    headers: getHeadersWithToken(),
    body: JSON.stringify(edition),
  });
}

export async function getAllEditions(selectedPage = 0, sortBy = 'id') {
  return fetch(`/api/v1/editions?pageNo=${selectedPage}&sortBy=${sortBy}`);
}

export async function getEditionsByWorkId(workId, selectedPage = 0) {
  return fetch(`/api/v1/works/${workId}/editions?pageNo=${selectedPage}`);
}

export async function getEditionsByPublisherId(
  publisherId,
  selectedPage = 0,
  sortBy = 'title'
) {
  return fetch(
    `/api/v1/publishers/${publisherId}/editions?pageNo=${selectedPage}&sortBy=${sortBy}`
  );
}

export async function getEditionsByIsbn(isbn, selectedPage = 0) {
  return fetch(
    `/api/v1/editions/search/isbn?isbn=${isbn}&pageNo=${selectedPage}`
  );
}

export async function searchEditionsByKeyword(
  keyword,
  selectedPage = 0,
  sortBy = 'title'
) {
  return fetch(
    `/api/v1/editions/search/title?keyword=${keyword}&pageNo=${selectedPage}&sortBy=${sortBy}`
  );
}

export async function deleteEdition(id) {
  return fetch(`/api/v1/editions/${id}`, {
    method: 'DELETE',
    headers: getHeadersWithToken(),
  });
}

/***  Work APIs */
export async function getWork(id) {
  return fetch(`/api/v1/works/${id}`);
}

export async function postWork(work) {
  return fetch('/api/v1/works', {
    method: 'POST',
    headers: getHeadersWithToken(),
    body: JSON.stringify(work),
  });
}

export async function addWorkToEdition(editionId, work) {
  return fetch(`/api/v1/editions/${editionId}/works`, {
    method: 'POST',
    headers: getHeadersWithToken(),
    body: JSON.stringify(work),
  });
}

export async function addWorkToAuthor(authorId, work) {
  return fetch(`/api/v1/authors/${authorId}/works`, {
    method: 'POST',
    headers: getHeadersWithToken(),
    body: JSON.stringify(work),
  });
}

export async function getAllWorks(selectedPage = 0) {
  return fetch(`/api/v1/works?pageNo=${selectedPage}`);
}

export async function getWorksByAuthorId(authorId, selectedPage = 0) {
  return fetch(`/api/v1/authors/${authorId}/works?pageNo=${selectedPage}`);
}

export async function getWorksByEditionId(editionId, selectedPage = 0) {
  return fetch(`/api/v1/editions/${editionId}/works?pageNo=${selectedPage}`);
}

export async function getWorksBySeriesId(seriesId, selectedPage = 0) {
  return fetch(`/api/v1/series/${seriesId}/works?pageNo=${selectedPage}`);
}

export async function searchWorksByKeyword(keyword, selectedPage = 0) {
  return fetch(
    `/api/v1/works/search?keyword=${keyword}&pageNo=${selectedPage}`
  );
}

export async function deleteWork(id) {
  return fetch(`/api/v1/works/${id}`, {
    method: 'DELETE',
    headers: getHeadersWithToken(),
  });
}

/*** Organization APIs */
export async function getAllOrganizations(selectedPage = 0, sortBy = 'id') {
  return fetch(`/api/v1/organizations?pageNo=${selectedPage}&sortBy=${sortBy}`);
}

export async function getOrganizationsByType(selectedPage = 0, type) {
  return fetch(`/api/v1/organizations?pageNo=${selectedPage}type=${type}`);
}

export async function getOrganizationsByParentId(selectedPage = 0, parentId) {
  return fetch(
    `/api/v1/organizations/${parentId}/branches?pageNo=${selectedPage}`
  );
}

export async function getLibrariesByParentId(selectedPage = 0, parentId) {
  return fetch(
    `/api/v1/organizations/${parentId}/libraries?pageNo=${selectedPage}`
  );
}

export async function searchOrganizations(keyword, selectedPage = 0) {
  return fetch(
    `/api/v1/organizations/search?pageNo=${selectedPage}&sortBy=name&keyword=${keyword}`
  );
}

export async function getArmenianLibraryProject() {
  return searchOrganizations('Armenian Library Project');
}

export async function getOrganization(id) {
  return fetch(`/api/v1/organizations/${id}`);
}

export async function postOrganization(organization) {
  return fetch('/api/v1/organizations', {
    method: 'POST',
    headers: getHeadersWithToken(),
    body: JSON.stringify(organization),
  });
}

export async function deleteOrganization(id) {
  return fetch(`/api/v1/organizations/${id}`, {
    method: 'DELETE',
    headers: getHeadersWithToken(),
  });
}

/*** Library APIs */
export async function getAllLibraries(selectedPage = 0) {
  return fetch(`/api/v1/libraries?pageNo=${selectedPage}`);
}

export async function searchLibraries(keyword, selectedPage = 0) {
  return fetch(
    `/api/v1/libraries/search?pageNo=${selectedPage}&sortBy=name&keyword=${keyword}`
  );
}

export async function getLibrariesByOrganizationId(
  organizationId,
  selectedPage = 0
) {
  return fetch(
    `/api/v1/organizations/${organizationId}/libraries?pageNo=${selectedPage}`
  );
}

export async function getLibrary(id) {
  return fetch(`/api/v1/libraries/${id}`);
}

export async function postLibrary(library) {
  return fetch('/api/v1/libraries', {
    method: 'POST',
    headers: getHeadersWithToken(),
    body: JSON.stringify(library),
  });
}

export async function deleteLibrary(id) {
  return fetch(`/api/v1/libraries/${id}`, {
    method: 'DELETE',
    headers: getHeadersWithToken(),
  });
}

/*** Address APIs */
export async function postAddress(address) {
  return fetch('/api/v1/addresses', {
    method: 'POST',
    headers: getHeadersWithToken(),
    body: JSON.stringify(address),
  });
}

export async function deleteAddress(id) {
  return fetch(`/api/v1/addresses/${id}`, {
    method: 'DELETE',
    headers: getHeadersWithToken(),
  });
}

/*** Book APIs */
export async function getAllBooks(selectedPage = 0, bookStatus = '') {
  var url = `/api/v1/books?pageNo=${selectedPage}`;
  if (bookStatus) {
    url += `&bookStatus=${bookStatus}`;
  }
  return fetch(url);
}

export async function getBooksByEditionId(
  selectedPage = 0,
  editionId,
  bookStatus = ''
) {
  var url = `/api/v1/editions/${editionId}/books?pageNo=${selectedPage}`;
  if (bookStatus) {
    url += `&bookStatus=${bookStatus}`;
  }
  return fetch(url);
}

export async function getBooksByOrganizationId(
  selectedPage = 0,
  organizationId,
  bookStatus = ''
) {
  var url = `/api/v1/organizations/${organizationId}/books?pageNo=${selectedPage}`;
  if (bookStatus) {
    url += `&bookStatus=${bookStatus}`;
  }
  return fetch(url);
}

export async function getBook(id) {
  return fetch(`/api/v1/books/${id}`);
}

export async function getBookByUuid(uuid) {
  return fetch(`/api/v1/catalog/${uuid}`);
}

export async function postBook(book) {
  return fetch('/api/v1/books', {
    method: 'POST',
    headers: getHeadersWithToken(),
    body: JSON.stringify(book),
  });
}

export async function deleteBook(id) {
  return fetch(`/api/v1/books/${id}`, {
    method: 'DELETE',
    headers: getHeadersWithToken(),
  });
}

/*** Contact APIs */
export async function postContact(contact) {
  return fetch('/api/v1/contacts', {
    method: 'POST',
    headers: getHeadersWithToken(),
    body: JSON.stringify(contact),
  });
}

export async function deleteContact(id) {
  return fetch(`/api/v1/contacts/${id}`, {
    method: 'DELETE',
    headers: getHeadersWithToken(),
  });
}

/*** DonationEvent APIs */
export async function getDonationEvent(id) {
  return fetch(`/api/v1/donation-events/${id}`);
}

export async function searchDonationEvents(keyword, selectedPage = 0) {
  return fetch(
    `/api/v1/donation-events/search?pageNo=${selectedPage}&sortBy=name&keyword=${keyword}`
  );
}

export async function postDonationEvent(donationEvent) {
  return fetch('/api/v1/donation-events', {
    method: 'POST',
    headers: getHeadersWithToken(),
    body: JSON.stringify(donationEvent),
  });
}

export async function deleteDonationEvent(id) {
  return fetch(`/api/v1/donation-events/${id}`, {
    method: 'DELETE',
    headers: getHeadersWithToken(),
  });
}

export async function getDonationEventsByOrganizationId(
  organizationId,
  selectedPage = 0
) {
  return fetch(
    `/api/v1/organizations/${organizationId}/donation-events?pageNo=${selectedPage}`
  );
}

/*** Donation APIs */
export async function getDonation(id) {
  return fetch(`/api/v1/donations/${id}`);
}

//"/books/{id}/donation
export async function getDonationByBookId(id) {
  return fetch(`/api/v1/books/${id}/donation`);
}

export async function postDonation(donation) {
  return fetch('/api/v1/donations', {
    method: 'POST',
    headers: getHeadersWithToken(),
    body: JSON.stringify(donation),
  });
}

export async function deleteDonation(id) {
  return fetch(`/api/v1/donations/${id}`, {
    method: 'DELETE',
    headers: getHeadersWithToken(),
  });
}

export async function getDonations(selectedPage = 0, sortBy = 'id') {
  return fetch(`/api/v1/donations?pageNo=${selectedPage}&sortBy=${sortBy}`);
}

export async function getDonationsByOrganizationId(
  organizationId,
  selectedPage = 0,
  sortBy = 'id'
) {
  return fetch(
    `/api/v1/organizations/${organizationId}/donations?pageNo=${selectedPage}&sortBy=${sortBy}`
  );
}

export async function getDonationsByDonationEventId(
  donationEventId,
  selectedPage = 0,
  sortBy = 'id'
) {
  return fetch(
    `/api/v1/donation-events/${donationEventId}/donations?pageNo=${selectedPage}&sortBy=${sortBy}`
  );
}

export async function getDonationsByDonorId(
  donorId,
  selectedPage = 0,
  sortBy = 'id'
) {
  return fetch(
    `/api/v1/donors/${donorId}/donations?pageNo=${selectedPage}&sortBy=${sortBy}`
  );
}

export async function getDonationsByShippingBoxId(
  shippingBoxId,
  selectedPage = 0,
  sortBy = 'id'
) {
  return fetch(
    `/api/v1/shipping-boxes/${shippingBoxId}/donations?pageNo=${selectedPage}&sortBy=${sortBy}`
  );
}

/*** PersonProfile APIs */
export async function getPersonProfiles(selectedPage = 0) {
  return fetch(`/api/v1/person-profiles?pageNo=${selectedPage}`);
}

export async function getPersonProfile(id) {
  return fetch(`/api/v1/person-profiles/${id}`);
}

export async function searchPersonProfilesByKeyword(keyword, selectedPage = 0) {
  return fetch(
    `/api/v1/person-profiles/search?keyword=${keyword.trim()}&pageNo=${selectedPage}`
  );
}

export async function postPersonProfile(personProfile) {
  return fetch('/api/v1/person-profiles', {
    method: 'POST',
    headers: getHeadersWithToken(),
    body: JSON.stringify(personProfile),
  });
}

export async function deletePersonProfile(id) {
  return fetch(`/api/v1/person-profiles/${id}`, {
    method: 'DELETE',
    headers: getHeadersWithToken(),
  });
}

/*** Publisher APIs */
export async function getAllPublishers(selectedPage = 0) {
  return fetch(`/api/v1/publishers?pageNo=${selectedPage}&sortBy=name`);
}

export async function searchPublishers(keyword, selectedPage = 0) {
  return fetch(
    `/api/v1/publishers/search?pageNo=${selectedPage}&sortBy=name&keyword=${keyword}`
  );
}

export async function getPublisher(id) {
  return fetch(`/api/v1/publishers/${id}`);
}

export async function deletePublisher(id) {
  return fetch(`/api/v1/publishers/${id}`, {
    method: 'DELETE',
    headers: getHeadersWithToken(),
  });
}

export async function postPublisher(publisher) {
  console.debug('post publisher', publisher);
  return fetch('/api/v1/publishers', {
    method: 'POST',
    headers: getHeadersWithToken(),
    body: JSON.stringify(publisher),
  });
}

async function findOrPostPublisher(publisher) {
  if (publisher.name?.length) {
    // Clean up the name
    publisher.name = cleanUpPublisher(publisher.name);
    console.debug('publisher cleaned up name:', publisher.name);

    if (publisher.name.length) {
      try {
        // First check if the publisher with this name already exists
        const searchPublishersResponse = await searchPublishers(publisher.name);
        const searchPublishersData = await searchPublishersResponse.json();

        if (isValidApiResponse(searchPublishersData)) {
          const publisherOptions = searchPublishersData.data.page;
          if (publisherOptions.length) {
            publisher = publisherOptions[0];
            console.debug('found publisher name:', publisher.name);
          }
        } else {
          // Handle errors
          console.error(
            'Error fetching publisher data:',
            searchPublishersData.message
          );
        }

        if (!publisher.id) {
          // Publisher not found, post publisher
          try {
            const postPublisherResponse = await postPublisher(publisher);
            const postPublisherData = await postPublisherResponse.json();

            if (isValidApiResponse(postPublisherData)) {
              console.debug(
                'valid post publisher response:',
                postPublisherData
              );
              publisher = postPublisherData;
            } else {
              console.error(
                'Error posting publisher ',
                publisher.name,
                postPublisherData?.message
              );
            }
          } catch (postError) {
            if (isDuplicateKeyError(postError)) {
              // If there is a duplicate key error, retry the search
              console.debug('Duplicate key error, retrying search...');
              const retrySearchResponse = await searchPublishers(
                publisher.name
              );
              const retrySearchData = await retrySearchResponse.json();

              if (isValidApiResponse(retrySearchData)) {
                const retryPublisherOptions = retrySearchData.data.page;

                if (retryPublisherOptions.length) {
                  publisher = retryPublisherOptions[0];
                  console.debug(
                    'found publisher name after retry:',
                    publisher.name
                  );
                }
              }
            } else {
              console.error('Error posting publisher:', postError);
            }
          }
        }
      } catch (error) {
        console.error(
          'Unexpected error in findOrPostPublisher:',
          error,
          publisher
        );
      }
    }
  }
  return publisher;
}

/*** Series APIs */
export async function getAllSeries(selectedPage = 0) {
  return fetch(`/api/v1/series?pageNo=${selectedPage}&sortBy=title`);
}

export async function searchSeries(keyword, selectedPage = 0) {
  return fetch(
    `/api/v1/series/search?pageNo=${selectedPage}&sortBy=title&keyword=${keyword}`
  );
}

export async function getSeriesById(id) {
  return fetch(`/api/v1/series/${id}`);
}

export async function deleteSeries(id) {
  return fetch(`/api/v1/series/${id}`, {
    method: 'DELETE',
    headers: getHeadersWithToken(),
  });
}

export async function postSeries(series) {
  console.debug('post series', series);
  return fetch('/api/v1/series', {
    method: 'POST',
    headers: getHeadersWithToken(),
    body: JSON.stringify(series),
  });
}

async function findOrPostSeries(series) {
  if (series.title?.length) {
    // clean up the title
    series.title = cleanUpString(series.title);
    console.debug('series cleaned up title:', series.title);

    if (series.title.length) {
      try {
        // First check if the series with this title already exists
        const searchSeriesResponse = await searchSeries(series.title);
        const searchSeriesData = await searchSeriesResponse.json();

        if (isValidApiResponse(searchSeriesData)) {
          const seriesOptions = searchSeriesData.data.page;
          if (seriesOptions.length) {
            series = seriesOptions[0];
            console.debug('found series title:', series.title);
          }
        } else {
          // Handle errors
          console.error(
            'Error fetching series data:',
            searchSeriesData.message
          );
        }

        if (!series.id) {
          //Series not found, post series
          try {
            const postSeriesResponse = await postSeries(series);
            const postSeriesData = await postSeriesResponse.json();

            if (isValidApiResponse(postSeriesData)) {
              console.debug('valid post series response:', postSeriesData);
              series = postSeriesData;
            } else {
              console.error(
                'Error posting series ',
                series.title,
                postSeriesData?.message
              );
            }
          } catch (postError) {
            if (isDuplicateKeyError(postError)) {
              // If there is a duplicate key error, retry the search
              console.debug('Duplicate key error, retrying search...');
              const retrySearchResponse = await searchSeries(series.title);
              const retrySearchData = await retrySearchResponse.json();

              if (isValidApiResponse(retrySearchData)) {
                const retrySeriesOptions = retrySearchData.data.page;

                if (retrySeriesOptions.length) {
                  series = retrySeriesOptions[0];
                  console.debug(
                    'found series title after retry:',
                    series.title
                  );
                }
              }
            } else {
              console.error('Error posting series:', postError);
            }
          }
        }
      } catch (error) {
        console.error('Unexpected error in findOrPostSeries:', error, series);
      }
    }
  }
  return series;
}

/*** Shipping Box APIs */
export async function getAllShippingBoxes(selectedPage = 0) {
  return fetch(`/api/v1/shipping-boxes?pageNo=${selectedPage}`);
}

export async function getShippingBoxesByOrganizationId(
  selectedPage = 0,
  organizationId
) {
  return fetch(
    `/api/v1/organizations/${organizationId}/shipping-boxes?pageNo=${selectedPage}`
  );
}

export async function getShippingBoxesByDonationEventId(
  selectedPage = 0,
  eventId,
  pageSize = 10
) {
  return fetch(
    `/api/v1/donation-events/${eventId}/shipping-boxes?pageSize=${pageSize}&pageNo=${selectedPage}`
  );
}

export async function getShippingBox(id) {
  return fetch(`/api/v1/shipping-boxes/${id}`);
}

export async function postShippingBox(shippingBox) {
  return fetch('/api/v1/shipping-boxes', {
    method: 'POST',
    headers: getHeadersWithToken(),
    body: JSON.stringify(shippingBox),
  });
}

export async function deleteShippingBox(id) {
  return fetch(`/api/v1/shipping-boxes/${id}`, {
    method: 'DELETE',
    headers: getHeadersWithToken(),
  });
}

/*** Edition/Work/Author combo APIs */
export async function postEditionWithWorkAndAuthors(edition) {
  if (!isValidEdition(edition)) {
    console.error(
      'Invalid edition ',
      edition.title,
      edition.isbn10,
      edition.isbn13,
      edition.openLibraryId
    );
    return;
  }
  var work = edition.works[0];
  if (!isValidWork(work)) {
    work.title = edition.title;
  }
  const authors = work.authors;

  //post authors
  var postedAuthors = [];
  for (const author of authors) {
    if (!isValidAuthor(author)) {
      console.error('Invalid author ', author.name, author.openLibraryId);
      continue;
    }
    var authorResponse = await (await postAuthor(author)).json();

    if (isValidApiResponse(authorResponse)) {
      postedAuthors.push(authorResponse);
    } else {
      console.error(
        'Error posting author ',
        author.name,
        authorResponse?.message
      );
    }
  }
  work.authors = postedAuthors;

  // handle publisher
  const publisher = await findOrPostPublisher(edition.publisher);
  console.log('Final publisher:', publisher);

  if (publisher.id) {
    edition.publisher = publisher;
  } else {
    edition.publisher = null;
  }

  // post edition
  var postedEditions = [];
  edition.works = [];
  var editionResponse = await (await postEdition(edition)).json();
  if (isValidApiResponse(editionResponse)) {
    postedEditions.push(editionResponse);
  } else {
    console.error(
      'Error posting edition with title',
      edition.title,
      editionResponse?.message
    );
  }
  work.editions = postedEditions;

  // handle series
  const series = await findOrPostSeries(work.series);
  console.log('Final series:', series);

  if (series.id) {
    work.series = series;
  } else {
    work.series = null;
  }

  // post work
  var workResponse = await (await postWork(work)).json();

  if (!isValidApiResponse(workResponse)) {
    console.error('Error posting work ', work.title, workResponse?.message);
  }
  return editionResponse;
}

/**
 * Fetches an entity if it is missing or invalid.
 */
export async function fetchEntity(
  currentEntity,
  id,
  currentRoute,
  expectedRoute,
  fetchFunction,
  emptyEntity
) {
  if (currentEntity?.id || currentRoute !== expectedRoute)
    return currentEntity || emptyEntity;

  return await fetchAndValidate(fetchFunction, id, emptyEntity);
}

/**
 * Helper function to fetch and validate API response.
 */
export async function fetchAndValidate(fetchFunction, id, emptyEntity) {
  const response = await (await fetchFunction(id)).json();
  return isValidApiResponse(response) ? response : emptyEntity;
}

export function isValidApiResponse(apiResponse) {
  return apiResponse?.message == null || apiResponse?.message == undefined;
}

export function isValidAuthor(author) {
  return !!author.name?.trim() || author.openLibraryId?.trim();
}
export function isValidWork(work) {
  return !!work.title?.trim(); // || work.openLibraryId?.trim();
}
export function isValidEdition(edition) {
  return !!edition.title?.trim() || edition.openLibraryId?.trim();
}

export function isValidName(name) {
  return !!name?.trim();
}

const cleanUpString = (input) => {
  if (typeof input !== 'string' || input.trim() === '') return '';

  // Trim leading and trailing whitespace
  let cleaned = input.trim();

  // Remove trailing characters: commas, #, ., -, (, ), and --
  cleaned = cleaned.replace(/[,#.\-()]+$|--$/, '');

  // Exclude specific strings
  const lowerCleaned = cleaned.toLowerCase();
  if (lowerCleaned === 'n/a' || lowerCleaned === 'undefined') {
    return '';
  }

  // Capitalize each word
  cleaned = cleaned.replace(/\b\w/g, (char) => char.toUpperCase());

  return cleaned;
};

const cleanUpPublisher = (input) => {
  // Trim leading and trailing whitespace
  let cleaned = cleanUpString(input);
  if (checkForScholastic(cleaned)) {
    return 'Scholastic';
  }

  return cleaned;
};

const checkForScholastic = (input) => {
  const regex = /scholastic|Scholastics|Scolastic/i;
  return regex.test(input) ? 'Scholastic' : null;
};

function isDuplicateKeyError(error) {
  // Simulate checking for a duplicate key error
  return error.message.includes('ConstraintViolationException');
}
