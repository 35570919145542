import React from 'react';
import ReactDOM from 'react-dom';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import BookQRCode from './utils/BookQRCode';
import { generateUUIDs } from './utils/CustomViewUtil';

const GeneratePDFWithBookQRCodes = () => {
  const generatePDFWithQRCodes = async (uuids) => {
    const qrCodeSize = 75; // Increased QR code size
    const spacing = 2; // Reduced spacing to minimize gaps
    const pageWidth = 210; // A4 width in mm
    const pageHeight = 297; // A4 height in mm
    const columns = 3;
    const rows = 4;
    const totalWidth = columns * qrCodeSize + (columns - 1) * spacing;
    const totalHeight = rows * qrCodeSize + (rows - 1) * spacing;
    const startX = (pageWidth - totalWidth) / 2; // Center the grid horizontally
    const startY = (pageHeight - totalHeight) / 2; // Center the grid vertically

    const doc = new jsPDF();

    let row = 0;
    let col = 0;

    for (const [index, uuid] of uuids.entries()) {
      const x = startX + col * (qrCodeSize + spacing);
      const y = startY + row * (qrCodeSize + spacing);

      const container = document.createElement('div');
      document.body.appendChild(container);

      await new Promise((resolve) => {
        ReactDOM.render(<BookQRCode bookUUID={uuid} />, container, resolve);
      });

      const qrCodeDiv = container.firstChild;
      await new Promise((r) => setTimeout(r, 200));

      await htmlToImage
        .toCanvas(qrCodeDiv, { backgroundColor: null })
        .then((finalCanvas) => {
          const imgData = finalCanvas.toDataURL('image/png');
          doc.addImage(imgData, 'PNG', x, y, qrCodeSize, qrCodeSize);
        });

      if (col < columns - 1) {
        doc.line(
          x + qrCodeSize + spacing / 2,
          startY,
          x + qrCodeSize + spacing / 2,
          startY + totalHeight
        );
      }
      if (row < rows - 1) {
        doc.line(
          startX,
          y + qrCodeSize + spacing / 2,
          startX + totalWidth,
          y + qrCodeSize + spacing / 2
        );
      }

      ReactDOM.unmountComponentAtNode(container);
      document.body.removeChild(container);

      col += 1;
      if (col === columns) {
        col = 0;
        row += 1;
      }
    }

    doc.save('QRCodes.pdf');
  };

  return (
    <div>
      <button onClick={() => generatePDFWithQRCodes(generateUUIDs(12))}>
        Generate PDF
      </button>
    </div>
  );
};

export default GeneratePDFWithBookQRCodes;
