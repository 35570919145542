import React, { Component } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from 'reactstrap';
import {
  postDonation,
  deleteDonation,
  searchOrganizations,
  searchDonationEvents,
  searchPersonProfilesByKeyword,
  getArmenianLibraryProject,
  getShippingBoxesByDonationEventId,
  isValidApiResponse,
} from './OpenGradaranApi.js';
import {
  EmptyOrganization,
  EmptyDonation,
  EmptyDonationEvent,
  EmptyBook,
  DonationStatus,
  DonationStatusOptions,
  ShippingStatus,
  ShippingStatusOptions,
  EmptyPersonProfile,
  EmptyShippingBox,
  getShippingBoxLabel,
} from './Constant.js';
import { backgroundStyle, inputDisableStyle } from './InlineStyles.js';
import {
  DeleteButton,
  CloseButton,
  SaveButton,
  StatusFormGroup,
  Pair,
} from './utils/CustomViewUtil.js';
import { withTranslation } from 'react-i18next';
import { getFullName } from './utils/TextUtil.js';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class DonationForm extends Component {
  //static contextType = AlpContext;

  constructor(props) {
    super(props);
    const { organization, book, donation } = this.props;
    this.state = {
      organization: organization || EmptyOrganization,
      book: book || EmptyBook,
      donation: donation || EmptyDonation,
      organizationSearchValue: '', // Value entered in the input box
      organizationOptions: organization ? [organization] : [],
      donationEvent: donation?.donationEvent || EmptyDonationEvent,
      donationEventSearchValue: '', // Value entered in the input box
      donationEventOptions: donation?.donationEvent
        ? [donation.donationEvent]
        : [],
      donor: donation?.donor || EmptyPersonProfile,
      donorSearchValue: '', // Value entered in the input box
      donorOptions: donation?.donor ? [donation.donor] : [],
      shippingBox: donation?.shippingBox || EmptyShippingBox,
      shippingBoxOptions: [],
      isLoading: false, // Loading state for API calls
      isEventDropdownVisible: false,
      isDonorDropdownVisible: false,
    };
    this.handleDonationChange = this.handleDonationChange.bind(this);

    this.handleOrganizationSelectionChange =
      this.handleOrganizationSelectionChange.bind(this);
    this.handleOrganizationInputChange =
      this.handleOrganizationInputChange.bind(this);

    this.handleDonationEventOptionClick =
      this.handleDonationEventOptionClick.bind(this);
    this.handleDonationEventInputChange =
      this.handleDonationEventInputChange.bind(this);
    this.handleDonorOptionClick = this.handleDonorOptionClick.bind(this);
    this.handleDonorInputChange = this.handleDonorInputChange.bind(this);

    this.handleDelete = this.handleDelete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.organization !== prevProps.organization ||
      this.props.donation !== prevProps.donation ||
      this.props.book !== prevProps.book
    ) {
      this.setState({
        organization: this.props.organization,
        book: this.props.book,
        donation: this.props.donation,
      });
    }
  }

  async componentDidMount() {
    var book = this.props.location.state?.book;
    var donation = this.props.location.state?.donation;
    var organization = this.props.location.state?.organization;

    if (!organization?.id) {
      const alpResponse = await (await getArmenianLibraryProject()).json();
      if (alpResponse.message == null) {
        const page = alpResponse.data.page;
        if (page?.length) {
          organization = page[0];
        }
      } else {
        // handle errors
        console.error('Error fetching data:', alpResponse.message);
      }
    }

    let donationEvent = donation.donationEvent || EmptyDonationEvent;
    var shippingBoxOptions = donation.shippingBox ? [donation.shippingBox] : [];
    if (donationEvent.id) {
      const optionsResponse = await (
        await getShippingBoxesByDonationEventId(0, donationEvent.id, 100)
      ).json();

      if (isValidApiResponse(optionsResponse.message)) {
        const options = optionsResponse.data.page;
        shippingBoxOptions = [EmptyShippingBox, ...options];
      } else {
        // handle errors
        console.error('Error fetching data:', optionsResponse.message);
      }
    }

    if (organization && donation && book) {
      donation.organization = organization;
      let donor = donation.donor || EmptyPersonProfile;
      let shippingBox = donation.shippingBox || EmptyShippingBox;
      this.setState({
        organization,
        book,
        donation,
        organizationSearchValue: '', // Value entered in the input box
        organizationOptions: [organization],
        donationEvent,
        donationEventSearchValue: donationEvent.name, // Value entered in the input box
        donationEventOptions: donation.donationEvent
          ? [donation.donationEvent]
          : [],
        donor,
        donorSearchValue: getFullName(donor), // Value entered in the input box
        donorOptions: donation.donor ? [donation.donor] : [],
        shippingBox,
        shippingBoxOptions,
        isLoading: false, // Loading state for API calls
        isEventDropdownVisible: false,
        isDonorDropdownVisible: false,
      });
    }
  }

  handleOrganizationSelectionChange(event) {
    const selectedName = event.target.value;
    const selectedOrganization = this.state.organizationOptions.find(
      (option) => option.name === selectedName
    );
    this.setState({
      //organizationSearchValue: selectedOrganization?.name,
      organization: selectedOrganization,
    });
  }

  async handleOrganizationInputChange(event) {
    event.stopPropagation();
    const value = event.target.value;
    this.setState({ organizationSearchValue: value, isLoading: true });

    this.findAndSetOrganization(value);
    this.setState({ isLoading: false });
  }

  async findAndSetOrganization(value = '') {
    const organization = this.state.organization;
    try {
      const optionsResponse = await (await searchOrganizations(value)).json();
      if (isValidApiResponse(optionsResponse.message)) {
        const options = optionsResponse.data.page;
        const filteredOptions = options.filter(
          (item) => item.id !== organization.id
        );
        this.setState({
          organizationOptions: [EmptyOrganization, ...filteredOptions],
        });
      } else {
        // handle errors
        console.error('Error fetching data:', optionsResponse.message);
      }
    } catch (error) {
      // handle errors
      console.error('Error fetching data:', error);
    }
  }

  handleDonationEventOptionClick(selectedDonationEvent) {
    this.getShippingBoxOptions(selectedDonationEvent);
    this.setState({
      donationEvent: selectedDonationEvent,
      donationEventSearchValue: selectedDonationEvent?.name,
      isEventDropdownVisible: false,
    });
  }

  async handleDonationEventInputChange(event) {
    event.stopPropagation();
    const value = event.target.value;
    this.setState({ donationEventSearchValue: value, isLoading: true });

    this.findAndSetDonationEvent(value);
    if (value.length > 0) {
      this.setState({ isLoading: false, isEventDropdownVisible: true });
    } else {
      this.setState({ isLoading: false, isEventDropdownVisible: false });
    }
  }

  async findAndSetDonationEvent(value = '') {
    const donationEvent = this.state.donationEvent;
    try {
      const optionsResponse = await (await searchDonationEvents(value)).json();
      if (isValidApiResponse(optionsResponse.message)) {
        const options = optionsResponse.data.page;
        this.setState({
          donationEventOptions: [EmptyDonationEvent, ...options],
        });
      } else {
        // handle errors
        console.error('Error fetching data:', optionsResponse.message);
      }
    } catch (error) {
      // handle errors
      console.error('Error fetching data:', error);
    }
  }

  handleDonorOptionClick(selectedDonor) {
    this.setState({
      donor: selectedDonor,
      donorSearchValue: getFullName(selectedDonor),
      isDonorDropdownVisible: false,
    });
  }

  async handleDonorInputChange(event) {
    event.stopPropagation();
    const value = event.target.value;
    this.setState({ donorSearchValue: value, isLoading: true });

    this.findAndSetDonor(value);
    if (value.length > 0) {
      this.setState({ isLoading: false, isDonorDropdownVisible: true });
    } else {
      this.setState({ isLoading: false, isDonorDropdownVisible: false });
    }
  }

  async findAndSetDonor(value = '') {
    const donor = this.state.donor;
    try {
      const optionsResponse = await (
        await searchPersonProfilesByKeyword(value)
      ).json();
      if (isValidApiResponse(optionsResponse.message)) {
        const options = optionsResponse.data.page;
        this.setState({
          donorOptions: [EmptyPersonProfile, ...options],
        });
      } else {
        // handle errors
        console.error('Error fetching data:', optionsResponse.message);
      }
    } catch (error) {
      // handle errors
      console.error('Error fetching data:', error);
    }
  }

  async getShippingBoxOptions(donationEvent) {
    if (!donationEvent?.id) {
      return;
    }
    try {
      const optionsResponse = await (
        await getShippingBoxesByDonationEventId(0, donationEvent.id, 100)
      ).json();
      if (isValidApiResponse(optionsResponse.message)) {
        const options = optionsResponse.data.page;
        this.setState({
          shippingBoxOptions: [EmptyShippingBox, ...options],
        });
      } else {
        // handle errors
        console.error('Error fetching data:', optionsResponse.message);
      }
    } catch (error) {
      // handle errors
      console.error('Error fetching data:', error);
    }
  }

  handleKeyDown = (event) => {
    if (event.key == 'Enter') {
      event.preventDefault();
    }
  };

  handleDonationChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let donation = { ...this.state.donation };
    const shippingBoxOptions = this.state.shippingBoxOptions;
    if (name === 'shippingBox') {
      const selectedShippingBox = shippingBoxOptions.find(
        (option) => option.id === Number(value)
      );
      donation[name] = selectedShippingBox;
    } else {
      donation[name] = value;
    }
    this.setState({ donation, shippingBox: donation.shippingBox });
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { organization, book, donation, donationEvent, donor } = this.state;

    donation.organization = organization;
    donation.book = book;
    donation.donor = donor?.id ? donor : null;
    donation.donationEvent = donationEvent?.id ? donationEvent : null;

    const donationResponse = await (await postDonation(donation)).json();
    if (isValidApiResponse(donationResponse)) {
      if (donationEvent?.id) {
        // this.props.navigate(`/donation-events/${donationEvent.id}/donations`, {
        //   state: { organization, donationEvent },
        // });
        this.props.navigate(-1);
      } else {
        this.props.navigate(`/organizations/${organization.id}/donations`, {
          state: { organization },
        });
      }
    } else {
      var error = donationResponse.message;
    }
  }

  async handleDelete(event) {
    event.preventDefault();

    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this donation?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    const { organization, donation, donationEvent } = this.state;
    if (donation.id) {
      try {
        const donationResponse = await deleteDonation(donation.id);
        if (isValidApiResponse(donationResponse)) {
          if (donationEvent?.id) {
            this.props.navigate(
              `/donation-events/${donationEvent.id}/donations`,
              {
                state: { organization, donationEvent },
              }
            );
          } else {
            this.props.navigate(`/organizations/${organization.id}/donations`, {
              state: { organization },
            });
          }
        } else {
          // Report invalid API response
          console.error('Invalid API response');
        }
      } catch (error) {
        // Handle network or other errors
        console.error('Error deleting donation:', error);
      }
    } else {
      this.props.navigate(-1);
    }
  }

  handleClose(event) {
    event.preventDefault();
    this.props.navigate(-1);
  }

  render() {
    const { t } = this.props;
    const {
      book,
      organization,
      donation,
      organizationOptions,
      organizationSearchValue,
      donationEvent,
      donationEventOptions,
      donationEventSearchValue,
      donor,
      donorOptions,
      donorSearchValue,
      shippingBox,
      shippingBoxOptions,
      isLoading,
      isEventDropdownVisible,
      isDonorDropdownVisible,
    } = this.state;

    if (!donation) {
      return null;
    }

    const bookProviderView = donation?.organization?.id ? (
      <p>
        <Pair
          label={'Book Provider'}
          value={organization.name}
          valueClass={'custom-text-blue'}
        />
      </p>
    ) : (
      <FormGroup>
        <Label htmlFor='organization'>Book Provider Organization</Label>
        <Input
          type='text'
          value={organizationSearchValue}
          onChange={this.handleOrganizationInputChange}
          onKeyDown={this.handleKeyDown}
          className='form-control'
          placeholder='Search...'
        />

        <select
          disabled={isLoading}
          value={organization.name}
          defaultValue={''}
          onChange={this.handleOrganizationSelectionChange}
          className='form-control'
          name='organizationt'
          id='organization'
        >
          {organizationOptions.map((option, index) => (
            <option key={index} value={option.name}>
              {option.name}
            </option>
          ))}
        </select>
      </FormGroup>
    );

    const bookView = book?.id ? (
      <h3>
        <Pair
          label={'Book'}
          value={book.edition.title}
          valueClass={'custom-text-blue'}
        />
      </h3>
    ) : null;

    return (
      <div>
        <div style={backgroundStyle}>
          <Container className='green-white'>
            <Row className='form-container'>
              <h2>{book.id ? t('Edit Donation') : t('Add Donation')}</h2>
              <Form onSubmit={this.handleSubmit}>
                {bookView}
                {bookProviderView}
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='donation-event'>Donation Event</Label>

                      <Input
                        type='text'
                        id='donation-event'
                        value={donationEventSearchValue}
                        onChange={this.handleDonationEventInputChange}
                        onKeyDown={this.handleKeyDown}
                        className='form-control'
                        placeholder='Search...'
                      />

                      {isEventDropdownVisible && (
                        <div className='dropdown-menu show'>
                          {donationEventOptions.map((option) => (
                            <Button
                              key={option.id}
                              className='dropdown-item'
                              onClick={() =>
                                this.handleDonationEventOptionClick(option)
                              }
                            >
                              {option.name}
                            </Button>
                          ))}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='id'>Donation ID</Label>
                      <Input
                        type='text'
                        name='id'
                        id='id'
                        value={donation.id}
                        onChange={this.handleDonationChange}
                        readOnly
                        style={inputDisableStyle}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='donationStatus'>Donation Status</Label>
                      <select
                        value={donation.donationStatus}
                        defaultValue={DonationStatus.NEW}
                        onChange={this.handleDonationChange}
                        className='form-control'
                        name='donationStatus'
                        id='donationStatus'
                      >
                        {DonationStatusOptions.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='shippingBox'>Shipping Box</Label>
                      <select
                        value={shippingBox?.id}
                        defaultValue={0}
                        onChange={this.handleDonationChange}
                        className='form-control'
                        name='shippingBox'
                        id='shippingBox'
                      >
                        {shippingBoxOptions.map((option) => (
                          <option value={option.id}>
                            {getShippingBoxLabel(option)}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='shippingStatus'>Shipping Status</Label>
                      <select
                        value={donation.shippingStatus}
                        defaultValue={ShippingStatus.NEW}
                        onChange={this.handleDonationChange}
                        className='form-control'
                        name='shippingStatus'
                        id='shippingStatus'
                      >
                        {ShippingStatusOptions.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='donor'>Donor</Label>
                      <Input
                        type='text'
                        id='donor'
                        value={donorSearchValue}
                        onChange={this.handleDonorInputChange}
                        onKeyDown={this.handleKeyDown}
                        className='form-control'
                        placeholder='Search...'
                      />

                      {isDonorDropdownVisible && (
                        <div className='dropdown-menu show'>
                          {donorOptions.map((option) => (
                            <Button
                              key={option.id}
                              className='dropdown-item'
                              onClick={() =>
                                this.handleDonorOptionClick(option)
                              }
                            >
                              {getFullName(option)}
                            </Button>
                          ))}
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor='source'>Source</Label>
                      <Input
                        type='text'
                        name='source'
                        id='source'
                        value={donation.source}
                        onChange={this.handleDonationChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='notes'>Notes</Label>
                      <Input
                        type='textarea'
                        rows={3}
                        name='notes'
                        id='notes'
                        value={donation.notes}
                        onChange={this.handleDonationChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <StatusFormGroup
                  status={donation.status}
                  handleChange={this.handleDonationChange}
                />
                <FormGroup>
                  <SaveButton />
                  <CloseButton onClick={this.handleClose} />
                  <DeleteButton onClick={this.handleDelete} />
                </FormGroup>
              </Form>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const HOCDonationForm = withRouter(withTranslation()(DonationForm));
export default HOCDonationForm;
