import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  getAllEditions,
  getEditionsByWorkId,
  getEditionsByPublisherId,
  searchEditionsByKeyword,
  getEditionsByIsbn,
  deleteEdition,
  isValidApiResponse,
} from './OpenGradaranApi';
import './styles/Edition.css';
import {
  PageFooter,
  BackToCatalog,
  BookCover,
  ComboTitle,
  ViewSubtitle,
  Pair,
} from './utils/CustomViewUtil.js';
import {
  EmptyWork,
  EmptyEdition,
  EmptyPublisher,
  ResourceName,
  canEditContent,
  LanguageOptions,
  getLabelByOptionValue,
} from './Constant.js';
import { AppContext } from './AppContext.js';
import { linkStyleNoUnderline } from './InlineStyles.js';
import { withTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { createTranslator, CaseFormat } from './utils/TranslationUtil.js';

/* This is a higher order component that
 *  inject a special prop   to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();

    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class EditionListPaginated extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      workFilter: { ...EmptyWork },
      publisherFilter: { ...EmptyPublisher },
      keyword: '',
      isbn: '',
      editions: [],
      currentPage: 0,
      totalPageCount: 0,
      totalElementCount: 0,
      pageOffset: 0,
      pageSize: 0,
      sortField: 'id',
    };
    this.remove = this.remove.bind(this);
    this.paginate = this.paginate.bind(this);
    this.handleSort = this.handleSort.bind(this);
  }

  async componentDidMount() {
    const queryParams = new URLSearchParams(this.props.location.search);
    const keyword = queryParams.get('keyword');
    const isbn = this.props.params.isbn;
    const { appState } = this.context;
    const selectedPage =
      appState.selectedResource === ResourceName.EDITION
        ? appState.selectedPage
        : 0;

    const filters = {
      workFilter: this.props.location.state?.work || null,
      publisherFilter: this.props.location.state?.publisher || null,
      keyword: keyword || null,
      isbn: isbn || null,
    };

    // Remove null values to avoid unnecessary updates
    const filteredState = Object.fromEntries(
      Object.entries(filters).filter(([_, value]) => value !== null)
    );

    this.setState(
      {
        ...filteredState,
        isLoading: true,
      },
      () => this.paginate({ selected: selectedPage })
    );
  }

  async componentDidUpdate(prevProps) {
    const { location, params } = this.props;

    // Check if pathname, search query, or ISBN has changed
    if (
      location.pathname !== prevProps.location.pathname ||
      location.search !== prevProps.location.search ||
      params.isbn !== prevProps.params.isbn
    ) {
      const queryParams = new URLSearchParams(location.search);
      const keyword = queryParams.get('keyword') || '';
      const isbn = params.isbn || '';

      this.setState(
        {
          isbn: isbn !== prevProps.params.isbn ? isbn : '',
          keyword: isbn ? '' : keyword,
          isLoading: true,
        },
        () => this.paginate({ selected: 0 })
      );
    }
  }

  async remove(id) {
    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this entry?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    this.setState({ isLoading: true });
    await deleteEdition(id).then((editionResponse) => {
      if (editionResponse.message == null) {
        let updatedEditions = [...this.state.editions].filter(
          (i) => i.id !== id
        );
        this.setState({ editions: updatedEditions, isLoading: false });
      } else {
        this.setState({ isLoading: false });
        //report error
      }
    });
  }

  async handleSort(field) {
    this.setState(
      { sortField: field, isLoading: true },
      () => this.paginate({ selected: 0 }) // Reset to the first page when sorting
    );
  }

  async paginate({ selected }) {
    const { updateAppSelectedPage } = this.context;
    updateAppSelectedPage(ResourceName.EDITION, selected);
    const { workFilter, publisherFilter, keyword, isbn, sortField } =
      this.state;
    this.setState({ currentPage: selected });
    var editionPageResponse;
    if (workFilter.id) {
      editionPageResponse = await (
        await getEditionsByWorkId(workFilter.id, selected)
      ).json();
    } else if (publisherFilter.id) {
      editionPageResponse = await (
        await getEditionsByPublisherId(publisherFilter.id, selected, sortField)
      ).json();
    } else if (keyword) {
      editionPageResponse = await (
        await searchEditionsByKeyword(keyword, selected, sortField)
      ).json();
    } else if (isbn) {
      editionPageResponse = await (
        await getEditionsByIsbn(isbn, selected)
      ).json();
    } else {
      editionPageResponse = await (
        await getAllEditions(selected, sortField)
      ).json();
    }
    if (isValidApiResponse(editionPageResponse)) {
      const respData = editionPageResponse.data;
      this.setState((state) => {
        return {
          isLoading: false,
          editions: respData.page,
          currentPage: selected,
          totalPageCount: respData.totalPages,
          totalElementCount: respData.totalElements,
          pageOffset: respData.offset,
          pageSize: respData.pageSize,
        };
      });
    }
  }

  getPageTitle(work, publisher) {
    const { t } = this.props;
    if (work.title) {
      return (
        <div>
          <h2>{t('Editions')}</h2>
          <ViewSubtitle
            name={work.title}
            resourceName={'Work'}
            nameClass={'dark-green'}
          />
        </div>
      );
    } else if (publisher.name) {
      return (
        <div>
          <h2>{t('Editions')}</h2>
          <ViewSubtitle
            name={publisher.name}
            resourceName={'Publisher'}
            nameClass={'dark-green'}
          />
        </div>
      );
    } else {
      return <h2>{t('Editions')}</h2>;
    }
  }

  render() {
    const { t } = this.props;
    const translate = createTranslator(t);
    const { appState } = this.context;
    const {
      isLoading,
      workFilter,
      publisherFilter,
      editions,
      currentPage,
      totalElementCount,
      totalPageCount,
      pageSize,
      pageOffset,
    } = this.state;

    if (isLoading) {
      return <p>Loading...</p>;
    }

    const pageTitle = this.getPageTitle(workFilter, publisherFilter);
    const editionList = editions.map((edition) => {
      const editionLanguage = getLabelByOptionValue(
        LanguageOptions,
        edition.language
      );
      return (
        <tr key={edition.id}>
          {canEditContent() && <td>{edition.id}</td>}
          <td>
            <Link to={`/editions/page/${edition.id}`} state={{ edition }}>
              <BookCover edition={edition} />
            </Link>
          </td>
          <td>
            <Link
              to={`/editions/page/${edition.id}`}
              state={{ edition }}
              style={linkStyleNoUnderline}
            >
              <ComboTitle title={edition.title} subtitle={edition.subtitle} />
            </Link>
          </td>
          {isMobile ? (
            <>
              <td>{edition.isbn13 || edition.isbn10}</td>
              <td>{edition.language}</td>
              <td>{edition.pageCount > 0 ? edition.pageCount : '-'}</td>
            </>
          ) : (
            <>
              <td>{edition.isbn13}</td>
              <td>{edition.isbn10}</td>
              <td>{translate(editionLanguage, CaseFormat.CAPITALIZED)}</td>
              {canEditContent() && <td>{edition.publisher?.name}</td>}
              {canEditContent() && <td>{edition.publishDate}</td>}
              <td>{edition.pageCount > 0 ? edition.pageCount : '-'}</td>
              {/* <td>{edition.status}</td> */}
            </>
          )}
          {canEditContent() && (
            <td>
              <ButtonGroup>
                <Button
                  size='sm'
                  color='primary'
                  tag={Link}
                  to={`/editions/${edition.id}`}
                  state={{ edition }}
                >
                  {t('Edit')}
                </Button>
                <Button
                  size='sm'
                  color='danger'
                  onClick={() => this.remove(edition.id)}
                >
                  {t('Delete')}
                </Button>
              </ButtonGroup>
            </td>
          )}
        </tr>
      );
    });

    return (
      <div>
        <Container fluid className='paginated-list-container'>
          <BackToCatalog />
          {canEditContent() && (
            <div className='float-right'>
              <Button
                color='success'
                tag={Link}
                to='/editions/new'
                state={{ edition: { ...EmptyEdition }, work: workFilter }}
              >
                {t('Add Edition')}
              </Button>
            </div>
          )}
          <br />
          {/* <h3>
            {work.title
              ? `${t('Editions of Work')}: "${work.title}"`
              : t('Editions')}
          </h3> */}
          <h3>{pageTitle}</h3>

          <Pair label={'Total'} value={totalElementCount} />

          <Table className='mt-4'>
            <thead>
              {isMobile ? (
                <tr>
                  {canEditContent() && (
                    <th
                      width='7%'
                      onClick={() => this.handleSort('id')}
                      style={{ cursor: 'pointer' }}
                    >
                      ID
                    </th>
                  )}
                  <th
                    width='10%'
                    onClick={() => this.handleSort('title')}
                    style={{ cursor: 'pointer' }}
                  >
                    {translate('edition', CaseFormat.CAPITALIZED)}
                  </th>
                  <th
                    width='20%'
                    onClick={() => this.handleSort('title')}
                    style={{ cursor: 'pointer' }}
                  >
                    {t('Title')}
                  </th>
                  <th width='10%'>ISBN</th>
                  <th width='10%'>{t('Language')}</th>
                  <th width='5%'>{t('Pages')}</th>
                  {canEditContent() && <th width='15%'>{t('Actions')}</th>}
                </tr>
              ) : (
                <tr>
                  {canEditContent() && (
                    <th
                      width='7%'
                      onClick={() => this.handleSort('id')}
                      style={{ cursor: 'pointer' }}
                    >
                      ID
                    </th>
                  )}
                  <th
                    width='10%'
                    onClick={() => this.handleSort('title')}
                    style={{ cursor: 'pointer' }}
                  >
                    {translate('edition', CaseFormat.CAPITALIZED)}
                  </th>
                  <th
                    width='15%'
                    onClick={() => this.handleSort('title')}
                    style={{ cursor: 'pointer' }}
                  >
                    {t('Title')}
                  </th>
                  <th width='10%'>ISBN13</th>
                  <th width='10%'>ISBN10</th>
                  <th width='10%'>{t('Language')}</th>
                  {canEditContent() && <th width='10%'>{t('Publisher')}</th>}
                  {canEditContent() && <th width='10%'>{t('Publish Date')}</th>}
                  <th width='5%'>{t('Pages')}</th>
                  {/* <th width='10%'>{t('Status')}</th> */}
                  {canEditContent() && <th width='15%'>{t('Actions')}</th>}
                </tr>
              )}
            </thead>
            <tbody>{editionList}</tbody>
          </Table>
          <PageFooter
            totalPageCount={totalPageCount}
            currentPage={currentPage}
            paginate={this.paginate}
          />
        </Container>
      </div>
    );
  }
}
const HOCEditionListPaginated = withRouter(
  withTranslation()(EditionListPaginated)
);
export default HOCEditionListPaginated;
