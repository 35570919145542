import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import {
  Link,
  useParams,
  useNavigate,
  useLocation,
  useMatch,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  getAllBooks,
  deleteBook,
  getBooksByEditionId,
  getBooksByOrganizationId,
  getOrganization,
  getEdition,
  fetchEntity,
  isValidApiResponse,
} from './OpenGradaranApi.js';
import {
  PageFooter,
  BackArrow,
  ViewTitle,
  ViewSubtitle,
  BookCover,
  hasCoverImage,
  Pair,
} from './utils/CustomViewUtil.js';
import {
  EmptyEdition,
  EmptyBook,
  EmptyOrganization,
  ResourceName,
  canEditContent,
  BookStatusOptions,
  BookConditionOptions,
  getLabelByOptionValue,
} from './Constant.js';
import { AppContext } from './AppContext.js';
import { withTranslation } from 'react-i18next';
import {
  getText,
  createTranslator,
  CaseFormat,
} from './utils/TranslationUtil.js';

/* This is a higher order component that
 *  inject a special prop   to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();

    const isOrgRoute = useMatch('/organizations/:id/books');
    const isEditionRoute = useMatch('/editions/:id/books');

    let routeType = '';
    if (isOrgRoute) routeType = 'organizations';
    if (isEditionRoute) routeType = 'editions';
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
        routeType={routeType}
      />
    );
  }
  return ComponentWithRouter;
}

class BookListPaginated extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    const { edition, organization } = this.props;

    this.state = {
      isLoading: false,
      edition: edition || { ...EmptyEdition },
      organization: organization || { ...EmptyOrganization },
      books: [],
      bookStatus: '',
      currentPage: 0,
      totalPageCount: 0,
      totalElementCount: 0,
      pageOffset: 0,
      pageSize: 0,
    };
    this.remove = this.remove.bind(this);
    this.paginate = this.paginate.bind(this);
  }

  async componentDidMount() {
    const { appState } = this.context;
    const { params, routeType, location } = this.props;
    const { id } = params;

    const selectedPage =
      appState.selectedResource === ResourceName.BOOK
        ? appState.selectedPage
        : 0;

    let edition = location.state?.edition;
    let organization = location.state?.organization;
    let bookStatus =
      new URLSearchParams(location.search).get('bookStatus') || ''; // Get initial bookStatus from URL

    organization = await fetchEntity(
      organization,
      id,
      routeType,
      'organizations',
      getOrganization,
      EmptyOrganization
    );

    edition = await fetchEntity(
      edition,
      id,
      routeType,
      'editions',
      getEdition,
      EmptyOrganization
    );

    this.setState(
      {
        organizationFilter: organization,
        editionFilter: edition,
        bookStatusFilter: bookStatus,
      },
      () => {
        this.setState({ isLoading: true });
        this.paginate({ selected: selectedPage });
      }
    );
  }

  async remove(id) {
    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this entry?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    this.setState({ isLoading: true });
    await deleteBook(id).then((bookResponse) => {
      if (bookResponse.message == null) {
        let updatedBooks = [...this.state.books].filter((i) => i.id !== id);
        this.setState({ books: updatedBooks, isLoading: false });
      } else {
        this.setState({ isLoading: false });
        //report error
      }
    });
  }

  getPageTitle(edition) {
    const { t } = this.props;
    if (!edition || !edition.id) {
      return t('Books');
    }
    if (hasCoverImage(edition)) {
      return <BookCover edition={edition} />;
    } else if (edition.title) {
      return edition.title;
    }
    return '';
  }

  async paginate({ selected }) {
    const { updateAppSelectedPage } = this.context;
    updateAppSelectedPage(ResourceName.BOOK, selected);

    const { editionFilter, organizationFilter, bookStatusFilter } = this.state;
    this.setState({ currentPage: selected });

    var bookPageResponse;
    if (editionFilter.id) {
      bookPageResponse = await (
        await getBooksByEditionId(selected, editionFilter.id, bookStatusFilter)
      ).json();
    } else if (organizationFilter.id) {
      bookPageResponse = await (
        await getBooksByOrganizationId(
          selected,
          organizationFilter.id,
          bookStatusFilter
        )
      ).json();
    } else {
      bookPageResponse = await (
        await getAllBooks(selected, bookStatusFilter)
      ).json();
    }
    if (isValidApiResponse(bookPageResponse)) {
      const respData = bookPageResponse.data;
      this.setState((state) => {
        return {
          isLoading: false,
          books: respData.page,
          currentPage: selected,
          totalPageCount: respData.totalPages,
          totalElementCount: respData.totalElements,
          pageOffset: respData.offset,
          pageSize: respData.pageSize,
        };
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { t } = this.props;
    const { appState } = this.context;
    const language = appState.language;

    const translate = createTranslator(t);
    const {
      isLoading,
      edition,
      organization,
      books,
      currentPage,
      totalElementCount,
      totalPageCount,
      pageSize,
      pageOffset,
    } = this.state;

    if (isLoading) {
      return <p>Loading...</p>;
    }

    let pageTitle = this.getPageTitle(edition);
    const orgName = getText(organization.name, organization.nameAm, language);
    let pageOrganizationName = organization?.id ? (
      <ViewSubtitle
        name={orgName}
        resourceName={'Organization'}
        nameClass={'dark-green'}
      />
    ) : null;

    let addBookButton =
      canEditContent() && edition?.id ? (
        <Button
          color='success'
          tag={Link}
          to='/books/new'
          state={{ edition: edition, book: { ...EmptyBook } }}
        >
          {t('Add Book')}
        </Button>
      ) : null;

    var bookNo = 0;
    const bookList = books.map((book) => {
      const bookOrgName = getText(
        book.organization?.name,
        book.organization?.nameAm,
        language
      );
      const bookStatus = getLabelByOptionValue(
        BookStatusOptions,
        book.bookStatus
      );

      const bookCondition = getLabelByOptionValue(
        BookConditionOptions,
        book.condition
      );

      return (
        <tr key={book.id}>
          {organization.id > 0 ? (
            <td>{++bookNo + pageOffset * pageSize}</td>
          ) : (
            <td>{book.id}</td>
          )}
          <td>
            <Link to={`/books/page/${book.id}`} state={{ book }}>
              <BookCover edition={book.edition} />
            </Link>
          </td>
          {canEditContent() && <td>{book.uuid}</td>}
          <td style={{ whiteSpace: 'nowrap' }}>
            <Link
              to={`/organizations/page/${book.organization?.id}`}
              state={{ organization: book.organization }}
            >
              {bookOrgName}
            </Link>
          </td>
          <td>{translate(bookCondition, CaseFormat.CAPITALIZED)}</td>
          <td>{translate(bookStatus, CaseFormat.CAPITALIZED)}</td>
          {/* <td>{book.status}</td> */}
          {/* <td>{book.dueDate?.toString()}</td> */}
          {canEditContent() && (
            <td>
              <ButtonGroup>
                <Button
                  size='sm'
                  color='primary'
                  tag={Link}
                  to={`/books/${book.id}`}
                  state={{ book: book }}
                >
                  {t('Edit')}
                </Button>
                <Button
                  size='sm'
                  color='danger'
                  onClick={() => this.remove(book.id)}
                >
                  {t('Delete')}
                </Button>
              </ButtonGroup>
            </td>
          )}
        </tr>
      );
    });

    return (
      <div>
        <Container fluid className='paginated-list-container'>
          <BackArrow />
          <div>{addBookButton}</div>
          <br />
          <h3>{pageTitle}</h3>
          {pageOrganizationName}

          <Pair label={'Total'} value={totalElementCount} />

          <Table className='mt-4'>
            <thead>
              <tr>
                {organization.id > 0 ? ( //if books retrieved by organization ID,
                  //we prefer to show ordinal numbers instead of IDs
                  <th width='5%'>#</th>
                ) : (
                  <th width='5%'>ID</th>
                )}
                <th width='10%'>{t('Book')}</th>
                {canEditContent() && <th width='15%'>{t('UUID')}</th>}
                <th width='15%'>{t('Library')}</th>
                {/* <th width='10%'>{t('Status')}</th> */}
                <th width='10%'>{t('Condition')}</th>
                <th width='10%'>{t('Book Status')}</th>
                {/* <th width='10%'>{t('Due Date')}</th> */}
                {canEditContent() && <th width='20%'>{t('Actions')}</th>}
              </tr>
            </thead>
            <tbody>{bookList}</tbody>
          </Table>
          <PageFooter
            totalPageCount={totalPageCount}
            currentPage={currentPage}
            paginate={this.paginate}
          />
        </Container>
      </div>
    );
  }
}
const HOCBookListPaginated = withRouter(withTranslation()(BookListPaginated));
export default HOCBookListPaginated;
