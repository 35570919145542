import React from 'react';
import { useTranslation } from 'react-i18next';

export const getText = (text, textAm, language) => {
  return language === 'en' ? text || textAm : textAm || text;
};

export const getSortField = (text, textAm, language) => {
  return language === 'en' ? text : textAm;
};

// Define an enum for case formats
export const CaseFormat = Object.freeze({
  LOWERCASE: 'lowercase',
  UPPERCASE: 'uppercase',
  CAPITALIZED: 'capitalized',
  DEFAULT: 'default',
});

// Utility function that accepts the `t` function
export const createTranslator =
  (t) =>
  (text, caseFormat = CaseFormat.DEFAULT) => {
    if (!text) return '';

    // Attempt translation, first with original text, then lowercase
    let translatedText = t(text);

    // If translation result is identical to input, try lowercase
    if (translatedText === text) {
      translatedText = t(text.toLowerCase());
    }

    // If still not translated, return the original text
    if (translatedText === text.toLowerCase()) {
      translatedText = text;
    }

    // Define case formatting options
    const formatMap = {
      [CaseFormat.UPPERCASE]: () => translatedText.toUpperCase(),
      [CaseFormat.CAPITALIZED]: () =>
        translatedText.charAt(0).toUpperCase() + translatedText.slice(1),
      [CaseFormat.LOWERCASE]: () => translatedText.toLowerCase(),
    };

    return formatMap[caseFormat]?.() || translatedText;
  };

// React Hook for functional components
export const useTranslate = () => {
  const { t } = useTranslation();
  return createTranslator(t);
};

// export const tToLowerCase = (text) => {
//   return translate(text, CaseFormat.LOWERCASE);
// };

// export const tToUpperCase = (text) => {
//   return translate(text, CaseFormat.UPPERCASE);
// };

// export const tToCapitalized = (text) => {
//   return translate(text, CaseFormat.CAPITALIZED);
// };
